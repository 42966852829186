/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-08
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  FormGroup,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  Typography,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';

// Assets
import BackIcon from 'assets/img/back_button_icon.svg';

// Data models
import { Props } from './propTypes';
import { SnoozifyUser, PaymentDetailModel } from 'models/user';

// Own components
import { RequestStatus } from 'models/common';
import SnoozifyTextField from 'components/common/text-field';
import SnoozifyButton from 'components/common/button';

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
      paddingBottom: '5rem',
      paddingLeft: '10%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '26.5rem',
      },
    },
    container: {
      height: '100%',
    },
    title: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.6rem',
      },
    },
    formControl: {
      minWidth: '100%',
    },
    formLabel: {
      fontSize: '1.6rem',
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    itemSpacing: {
      padding: '0 0 3.75rem 0',
    },
    progressContainer: {
      textAlign: 'center',
    },
    snackbarContent: {
      backgroundColor: green[600],
    },
    snackbarIcon: {
      marginRight: '1rem',
    },
    snackbarMessage: {
      backgroundColor: 'inherit',
      display: 'flex',
    },
    backButton: {
      padding: '0',
      position: 'absolute',
      left: '0',
      width: '6.4rem',
      height: '6.4rem',
      transform: 'translate(-2rem, -0.7rem)',
      top: '0rem',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      color: 'white',
      width: '100%',
      height: '100%',
    },
    iconLabel: {
      display: 'contents',
    },
  }),
);

enum SnackBarContent {
  save = 'save',
  none = '',
}

const PaymentDetails: React.FC<Props> = (props) => {
  const { profile, updateUserProfile, loadUserProfile, unsubscribe, progress } = props;
  const [updatedUserProfile, setUpdatedUserProfile] = useState<SnoozifyUser>(profile);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarContent] = useState<SnackBarContent>(SnackBarContent.save);
  const unsubscribeRef = useRef(unsubscribe);
  const history = useHistory();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  useEffect(() => {
    setUpdatedUserProfile(profile);
    setShowLoadingAnimation(false);
  }, [profile]);

  useEffect(() => {
    // set the loading animation here, but wait for the profile to be updated until rendering
    // otherwise there will be unwanted animation because the  progress can be updated before / simultaneously to profile
    // this is a problem when the redux store is still in its initial state
    // add a timeout to be failsafe
    if (progress === RequestStatus.LOADING) {
      setShowLoadingAnimation(true);
      setTimeout(() => {
        setShowLoadingAnimation(false);
      }, 2000);
    }
  }, [progress]);

  useEffect(() => {
    unsubscribeRef.current = unsubscribe;
  }, [unsubscribe]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 2000);
    }
  }, [snackbarOpen]);

  const onInputPaymentDetails = <K extends keyof PaymentDetailModel>(
    value: PaymentDetailModel[K],
    field: K,
  ) => {
    let update: SnoozifyUser = {
      ...updatedUserProfile,
    };
    update.paymentDetails[field] = value;
    setUpdatedUserProfile(update);
  };

  const returnAction = () => {
    history.goBack();
  };

  const updateAction = () => {
    updateUserProfile(updatedUserProfile);
    setSnackbarOpen(true);
    setTimeout(() => {
      returnAction();
    }, 800);
  };

  const getSnackbarContent = (): React.ReactElement => {
    let content: React.ReactElement | string;
    switch (snackbarContent) {
      case SnackBarContent.save:
        content = (
          <span className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            <Typography align="center">{t('account.saveDataSuccess')}</Typography>
          </span>
        );
        break;
      default:
        content = '';
        break;
    }

    return <SnackbarContent className={classes.snackbarContent} message={content} />;
  };

  return (
    <Grid container className={classes.root} justify="center">
      {showLoadingAnimation ? null : (
        <IconButton
          className={classes.backButton}
          classes={{ label: classes.iconLabel }}
          edge="start"
          arial-label="Snoozify-logo"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={() => {
            returnAction();
          }}
        >
          <img src={BackIcon} alt="Snoozify icon with text Snoozify" className={classes.icon}></img>
        </IconButton>
      )}
      {showLoadingAnimation ? (
        <Grid container alignItems="center" className={classes.container} justify="center">
          <Grid item xs={12} className={classes.progressContainer}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          justify="flex-start"
          alignContent="center"
          className={classes.container}
        >
          <Grid item xs={12}>
            <FormGroup>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item>
                  <Grid item>
                    <Grid item style={{ paddingBottom: '6.4rem' }}>
                      <Typography align="left" variant="h2" className={classes.title}>
                        {t('account.editPaymentDetails')}
                      </Typography>
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        label={t('labels.accountHolder')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputPaymentDetails(event.target.value, 'accountHolder')
                        }
                        type="text"
                        value={updatedUserProfile.paymentDetails.accountHolder}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        label={t('labels.IBAN')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputPaymentDetails(event.target.value, 'IBAN')
                        }
                        type="text"
                        value={updatedUserProfile.paymentDetails.IBAN}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        label={t('labels.BIC')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputPaymentDetails(event.target.value, 'BIC')
                        }
                        type="text"
                        value={updatedUserProfile.paymentDetails.BIC}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        label={t('labels.bankName')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputPaymentDetails(event.target.value, 'bankName')
                        }
                        type="text"
                        value={updatedUserProfile.paymentDetails.bankName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} container style={{ paddingTop: '2.65rem' }}>
                      <span style={{ paddingRight: '2.1rem', paddingTop: '2rem' }}>
                        <SnoozifyButton primaryColor={false} onClick={returnAction}>
                          {t('common.cancel')}
                        </SnoozifyButton>
                      </span>
                      <span style={{ paddingTop: '2rem' }}>
                        <SnoozifyButton onClick={updateAction}>{t('common.save')}</SnoozifyButton>
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      )}
      <Snackbar open={snackbarOpen}>{getSnackbarContent()}</Snackbar>
    </Grid>
  );
};

export default PaymentDetails;
