/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import * as React from 'react';
import { FC, Fragment } from 'react';

// Data models
import { Props } from './propTypes';
// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    '&:hover,&:active,&:focus': {
      backgroundColor: (props) =>
        props.cta ? theme.palette.secondary.dark : theme.palette.primary.dark,
      borderColor: (props) =>
        props.cta ? theme.palette.secondary.dark : theme.palette.primary.dark,
      color: 'white',
    },
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.down('xs')]: {},
    [theme.breakpoints.up('sm')]: {},
    backgroundColor: (props) =>
      !props.primaryColor
        ? theme.palette.primary.contrastText
        : props.cta
        ? theme.palette.secondary.main
        : theme.palette.primary.dark,
    borderRadius: '1.2rem',
    fontSize: (props) => (props.small ? '1.4rem' : '1.6rem'),
    lineHeight: (props) => (props.small ? '1.7rem' : '2.1rem'),
    color: (props) =>
      props.primaryColor
        ? 'white'
        : props.cta
        ? theme.palette.secondary.main
        : theme.palette.primary.dark,
    border: (props) =>
      props.primaryColor
        ? 'unset'
        : props.cta
        ? `2px solid ${theme.palette.secondary.main}`
        : `2px solid ${theme.palette.primary.dark}`,
    width: (props) => {
      switch (props.buttonWidth) {
        case '>=8Chars':
          return props.small ? '15.7rem' : '16rem';
        case '<3Chars':
          return props.small ? '9.8rem' : '10rem';
        case '<8Chars':
          return props.small ? '14.1rem' : '14.4rem';
        case '>12Chars':
          return props.small ? '28.4rem' : '29rem';
      }
    },
    height: (props) => (props.small ? '3.2rem' : '4rem'),
  },
  label: {
    textTransform: 'none',
    fontSize: (props) => (props.small ? '1.4rem' : '1.6rem'),
  },
  focused: {},
}));

const SnoozifyButton: FC<Props> = (props) => {
  const { cta, small, primaryColor, buttonWidth, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Fragment>
      <Button
        {...rest}
        classes={{ root: classes.root, label: classes.label }}
        disableFocusRipple
        fullWidth
        size="small"
        variant="contained"
      />
    </Fragment>
  );
};

SnoozifyButton.defaultProps = {
  cta: false,
  primaryColor: true,
  small: false,
  buttonWidth: '>=8Chars',
};

export default SnoozifyButton;
