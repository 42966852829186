/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import {
  LoginData,
  LoginError,
  RequestPasswordResetData,
  RequestPasswordResetError,
  ResetPasswordError,
} from 'models/login';
import { RequestStatus } from 'models/common';

export enum LoginActionType {
  DATA = '@@login/DATA',
  DATA_RESET = '@@login/DATA_RESET',
  LOGIN_STATUS = '@@login/STATUS',
  REQUEST_RESET_DATA = '@@login/REQUEST_RESET_DATA',
  REQUEST_RESET_STATUS = '@@login/REQUEST_RESET_STATUS',
  RESET_STATUS = '@@login/RESET_STATUS',
}

export interface LoginDataAction {
  data: LoginData;
  type: LoginActionType.DATA;
}

export interface LoginDataResetAction {
  type: LoginActionType.DATA_RESET;
}

export interface LoginStatusAction {
  error: LoginError;
  status: RequestStatus;
  type: LoginActionType.LOGIN_STATUS;
}

export interface RequestResetDataAction {
  data: RequestPasswordResetData;
  type: LoginActionType.REQUEST_RESET_DATA;
}

export interface RequestResetStatusAction {
  error: RequestPasswordResetError;
  status: RequestStatus;
  type: LoginActionType.REQUEST_RESET_STATUS;
}

export interface ResetPasswordStatusAction {
  error: ResetPasswordError;
  status: RequestStatus;
  type: LoginActionType.RESET_STATUS;
}
export type LoginAction =
  | LoginDataAction
  | LoginDataResetAction
  | LoginStatusAction
  | RequestResetDataAction
  | RequestResetStatusAction
  | ResetPasswordStatusAction;
