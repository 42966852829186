/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-02-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Reducer } from 'redux';

// Data models
import { CommonState } from '../../models/common/state';
import { CommonAction, CommonActionType } from '../../models/common/actions';
import { Language } from '../../models/common';

export const initialState: CommonState = {
  drawerOpen: false,
  language: Language.DE,
};

const commonReducer: Reducer<CommonState, CommonAction> = (state = initialState, action) => {
  switch (action.type) {
    case CommonActionType.LANGUAGE_CHANGE: {
      return {
        ...state,
        language: action.language,
      };
    }
    case CommonActionType.TOGGLE_DRAWER: {
      return {
        ...state,
        drawerOpen: action.open,
      };
    }
    default: {
      return state;
    }
  }
};

export default commonReducer;
