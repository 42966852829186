/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  CircularProgress,
  Grid,
  Link,
  Snackbar,
  SnackbarContent,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import * as React from 'react';
import { ChangeEvent, FC, Fragment, KeyboardEvent, ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Own components
import PasswordField from 'components/common/password-field';
import SnoozifyTextField from 'components/common/text-field';

// Config
import { FORGOT_PASSWORD_PATH, SIGN_UP_PATH } from 'config/routes';

// Action creators
import { login, resetLoginData, updateLoginData } from 'store/login/actions';

// Data models
import { LoginData, LoginError } from 'models/login';
import { RequestStatus } from 'models/common';
import SnoozifyButton from 'components/common/button';
import { RootState } from 'store';

interface StateProps {
  data: LoginData;
  error: LoginError;
  redirectUrl: string;
  status: RequestStatus;
}

// Styles
const useStyles = makeStyles<Theme, StateProps>((theme) => ({
  container: {
    height: '100%',
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  errorMessage: {
    marginTop: '1rem',
  },
  link: {
    cursor: 'pointer',
  },
  title: {
    paddingTop: theme.spacing(21),
    paddingBottom: theme.spacing(15),
  },
  snackbarContent: {
    backgroundColor: (props) => (props.status === RequestStatus.SUCCESS ? green[600] : ''),
  },
  snackbarIcon: {
    marginRight: '1rem',
  },
  snackbarMessage: {
    backgroundColor: 'inherit',
    display: 'flex',
  },
  buttonSpacing: {
    paddingTop: theme.spacing(13),
  },
  inputSpacing: {
    paddingBottom: theme.spacing(9),
  },
  checkboxSpacing: {
    paddingTop: theme.spacing(10),
    paddingLeft: '0.9rem',
  },
  linkText: {
    '&:hover': {
      color: '#0C5DE8',
    },
  },
}));

const Login: FC = () => {
  const dispatch = useDispatch();
  const props = useSelector<RootState, StateProps>((state) => ({
    data: state.login.loginData,
    error: state.login.loginError,
    redirectUrl: state.auth.redirectUrl,
    status: state.login.loginStatus,
  }));
  const { data, error, redirectUrl, status } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (status === RequestStatus.SUCCESS) {
      dispatch(resetLoginData());
      setTimeout(() => {
        history.push(redirectUrl);
      }, 1000);
    }
  }, [dispatch, history, redirectUrl, status]);

  // Clean up on leaving login page
  /*useEffect(
    () => () => {
      dispatch(resetLoginData());
    },
    [dispatch],
  );*/

  /**
   * Handles inputs from Input elements and updates the corresponding value at the store with the
   * dispatcher.
   *
   * @param key - Target field of login data
   * @param value - New value from input
   * @param data - Login data with all entered values
   * @param updateData - Update data action dispatcher
   */
  const onInput = <K extends keyof LoginData>(key: K, value: LoginData[K]) => {
    let update = {
      ...data,
    };
    update[key] = value;
    dispatch(updateLoginData(update));
  };

  /**
   * Checks all keyboard inputs if the enter key has been pressed and for that case will start
   * the login progress against Firebase.
   *
   * @param event - Keyboard event from input element
   * @param data - Login data from input dialogs
   * @param login - Login action dispatcher
   */
  const onKeyPressed = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      dispatch(login(data));
    }
  };

  /**
   * Returns an error message describing the reason for the failed login. Will only return
   * content if there occured an error.
   *
   * @param error - Login error reason
   * @param t - Translation function from i18n-next
   */
  const getErrorMessage = (): string => {
    switch (error) {
      case LoginError.INVALID_EMAIL: {
        return t('common.emailInvalid');
      }
      case LoginError.USER_DISABLED: {
        return t('common.userDisabled');
      }
      case LoginError.USER_NOT_FOUND:
      case LoginError.WRONG_PASSWORD: {
        return t('login.wrongCredentials');
      }
      default: {
        return '';
      }
    }
  };

  /**
   * Returns the content for the snackbar related to current status of login progress against
   * Firebase. Will only return content if status is in loading or success state.
   *
   * @param status - Status of request to login
   * @param t - Translation function of i18n-next
   */
  const getSnackbarContent = (): ReactElement => {
    let content: string | ReactElement;
    switch (status) {
      case RequestStatus.LOADING: {
        content = (
          <span className={classes.snackbarMessage}>
            <CircularProgress size={30} className={classes.snackbarIcon} />
            <Typography align="center">{t('common.oneMomentPlease')}</Typography>
          </span>
        );
        break;
      }
      case RequestStatus.SUCCESS: {
        content = (
          <span className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            <Typography align="center">{t('login.succeeded')}</Typography>
          </span>
        );
        break;
      }
      default: {
        content = '';
        break;
      }
    }
    return <SnackbarContent className={classes.snackbarContent} message={content} />;
  };

  return (
    <Fragment>
      <Helmet title={`${t('login.title')} | Snoozify`}></Helmet>
      <Grid className={classes.container} container alignItems="flex-start" justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.title}>
            {t('login.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form>
            <Grid item xs={12}>
              <Grid item xs={12} container justify="center">
                <Grid
                  container
                  alignItems="flex-start"
                  justify="center"
                  style={{ width: 'min-content' }}
                >
                  <Grid item xs={12} className={classes.inputSpacing}>
                    <SnoozifyTextField
                      autoComplete="username"
                      autoFocus
                      snoozifyVariant={mediumSize ? 'large' : 'medium'}
                      error={status === RequestStatus.ERROR && error !== LoginError.WRONG_PASSWORD}
                      fullWidth
                      helperText={error !== LoginError.WRONG_PASSWORD ? getErrorMessage() : ''}
                      label={t('labels.email')}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onInput('email', event.target.value);
                      }}
                      onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                        onKeyPressed(event);
                      }}
                      size="small"
                      type="email"
                      value={data.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordField
                      checkPassword={false}
                      snoozifyVariant={mediumSize ? 'large' : 'medium'}
                      autoComplete="current-password"
                      error={status === RequestStatus.ERROR && error === LoginError.WRONG_PASSWORD}
                      fullWidth
                      helperText={error === LoginError.WRONG_PASSWORD ? getErrorMessage() : ''}
                      label={t('labels.password')}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onInput('password', event.target.value);
                      }}
                      onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                        onKeyPressed(event);
                      }}
                      size="small"
                      value={data.password}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center" className={classes.buttonSpacing}>
            <Grid item container justify="center" xs={12}>
              <SnoozifyButton
                buttonWidth=">=8Chars"
                color="primary"
                fullWidth
                variant="outlined"
                onClick={() => {
                  dispatch(login(data));
                }}
                onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
                  onKeyPressed(event);
                }}
              >
                {t('labels.login')}
              </SnoozifyButton>
            </Grid>
            <Grid item container justify="center" xs={12} style={{ paddingTop: '1.6rem' }}>
              <Typography align="center">
                <Link
                  variant="body1"
                  className={classes.link}
                  onClick={() => history.push(FORGOT_PASSWORD_PATH)}
                >
                  {t('login.forgotPassword')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '5.2rem' }}>
          <Grid container alignItems="center" justify="center">
            <Grid item container justify="center" xs={12}>
              <Typography align="center">{t('login.noAccountYet')}</Typography>
            </Grid>
            <Grid item container justify="center" xs={12} style={{ paddingTop: '1.6rem' }}>
              <Link
                variant="body1"
                color="primary"
                onClick={(): void => history.push(SIGN_UP_PATH)}
              >
                {t('login.registerNow')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar open={status === RequestStatus.SUCCESS || status === RequestStatus.LOADING}>
        {getSnackbarContent()}
      </Snackbar>
    </Fragment>
  );
};

export default Login;
