/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-14
 * @author Tim Lange <tl@systl.de>
 */

import { APPLICATION_JSON } from './mimeTypes';

export const getJsonHeaders = new Headers({
  Accept: APPLICATION_JSON,
});
export const postJsonHeaders = new Headers({
  Accept: APPLICATION_JSON,
  'Content-Type': APPLICATION_JSON,
});
