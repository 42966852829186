/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

// Action creators
import { deleteAccount } from 'store/auth/authSlice';

// Presentational component
import DeleteAccountDialog from './DeleteAccountDialog';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { RootState, AppDispatch } from 'store';

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (state) => ({
  error: state.auth.deleteAccountError,
  progress: state.auth.deleteAccountState,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (dispatch: AppDispatch) => ({
  onDeleteAccount: () => {
    dispatch(deleteAccount());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountDialog);
