/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-02-20
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

//Common
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const DEBUG = NODE_ENV !== 'production';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION || '';

// Firebase
export const FB_API_KEY = process.env.REACT_APP_FB_API_KEY;
export const FB_AUTH_DOMAIN = process.env.REACT_APP_FB_AUTH_DOMAIN;
export const FB_DATABASE_URL = process.env.REACT_APP_FB_DATABASE_URL;
export const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
export const FB_STORAGE_BUCKET = process.env.REACT_APP_FB_STORAGE_BUCKET;
export const FB_MESSAGING_SENDER_ID = process.env.REACT_APP_FB_MESSAGING_SENDER_ID;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const FB_MEASUREMENT_ID = process.env.REACT_APP_FB_MEASUREMENT_ID;
