/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { FC, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';

// Store and connected history
import { history, store } from 'store';

// Theme
import blueTheme from 'themes/snoozify_main_theme';

// Root view
import RootView from 'views/root';

// Loader
import ProgressLogo from 'components/common/progress-logo';

// Translations
import i18nPortal from 'utils/i18n';

const App: FC = () => {
  return (
    <Suspense fallback={<ProgressLogo size="4rem" speed={2000} />}>
      <StoreProvider store={store}>
        <ConnectedRouter history={history}>
          <I18nextProvider i18n={i18nPortal}>
            <MuiThemeProvider theme={blueTheme}>
              <RootView />
            </MuiThemeProvider>
          </I18nextProvider>
        </ConnectedRouter>
      </StoreProvider>
    </Suspense>
  );
};

export default App;
