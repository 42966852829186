/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-07-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { FireStoreError, FireStorePermissionCreateError } from 'models/firebase';
import { RequestStatus } from 'models/common';
import { Reducer } from 'redux';
import { PermissionAction, PermissionActionType } from 'models/permission/actions';
import { PermissionState } from 'models/permission/state';

export const initialState: PermissionState = {
  currentWorkspaceId: null,
  loadPermissionsError: FireStoreError.NONE,
  loadPermissionsStatus: RequestStatus.IDLE,
  createPermissionsStatus: RequestStatus.IDLE,
  createPermissionsError: FireStorePermissionCreateError.NONE,
  updatePermissionsUnsubscribe: () => {},
  updateGrantedPermissionsUnsubscribe: () => {},
  permissions: [],
  grantedPermissions: [],
  invitationMail: '',
};

const permissionReducer: Reducer<PermissionState, PermissionAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PermissionActionType.LOAD_PERMISSIONS_STATUS: {
      return {
        ...state,
        loadPermissionsError: action.error,
        loadPermissionsStatus: action.status,
      };
    }
    case PermissionActionType.CREATE_PERMISSIONS_STATUS: {
      return {
        ...state,
        createPermissionsStatus: action.status,
        createPermissionsError: action.error,
      };
    }
    case PermissionActionType.SET_INVITATION_MAIL: {
      return {
        ...state,
        invitationMail: action.email,
      };
    }
    case PermissionActionType.SET_CURRENT_WORKSPACE: {
      return {
        ...state,
        currentWorkspaceId: action.workspaceId,
      };
    }
    case PermissionActionType.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.permissions,
      };
    }
    case PermissionActionType.SET_GRANTED_PERMISSIONS: {
      return {
        ...state,
        grantedPermissions: action.grantedPermissions,
      };
    }
    case PermissionActionType.UPDATE_PERMISSIONS_UNSUBSCRIBE: {
      return {
        ...state,
        updatePermissionsUnsubscribe: action.unsubscribe,
      };
    }
    case PermissionActionType.UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE: {
      return {
        ...state,
        updateGrantedPermissionsUnsubscribe: action.unsubscribe,
      };
    }
    default: {
      return state;
    }
  }
};

export default permissionReducer;
