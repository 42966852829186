/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  Typography,
  SnackbarContent,
  CircularProgress,
  Card,
  CardContent,
  Link,
  Snackbar,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import * as countries from 'i18n-iso-countries';
import { useHistory } from 'react-router-dom';

// Data models
import { Props } from './propTypes';

// Own components
import ChangeEmailDialog from './ChangeEmailDialog/ChangeEmailDialogContainer';
import ChangePasswordDialog from './ChangePasswordDialog/ChangePasswordDialogContainer';
import DeleteAccountDialog from './DeleteAccountDialog/DeleteAccountDialogContainer';
import AccountDetailCard from 'components/account/AccountDetailCard';

// Register language definitions for country selection
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',

      padding: '0 1rem 8.4rem',
      [theme.breakpoints.up('md')]: {
        padding: '0 14rem 8.4rem',
      },
    },
    container: {
      height: '100%',
    },
    formControl: {
      minWidth: '100%',
    },
    selectMenu: {
      textAlign: 'left',
    },
    subCard: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% - 3.2rem)',
      },
      paddingBottom: '8.4rem',
    },
    formLabel: {
      textAlign: 'left',
    },

    buttonText: {},
    buttonLinkText: {
      fontWeight: 400,
    },
    snackbarContent: {
      backgroundColor: green[600],
    },
    snackbarIcon: {
      marginRight: '1rem',
    },
    snackbarMessage: {
      backgroundColor: 'inherit',
      display: 'flex',
    },
    progressContainer: {
      textAlign: 'center',
    },
    contactLinkContainer: {
      paddingLeft: '1.6rem',
    },
    closeAccountCard: {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: 'unset',
      //minHeight: '8.4rem',
    },
    closeAccountLink: {
      fontSize: '1.2rem',
    },
    closeAccountCardContent: {
      padding: '1.6rem',
      '&:last-child': {
        paddingBottom: '1.6rem',
      },
    },
  }),
);

enum SnackBarContent {
  email = 'email',
  password = 'password',
  none = '',
}

// TODO: set country language according to language setting
const Main: React.FC<Props> = (props) => {
  const { profile } = props;
  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState<boolean>(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState<boolean>(false);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] = useState<SnackBarContent>(SnackBarContent.none);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    // when the profileInfoUpdated is null, the profile was not loaded yet
    // assuming the profile is loading inside the parent component
    if (profile.profileInfoUpdated === null) {
      setShowLoadingAnimation(true);
    } else {
      setShowLoadingAnimation(false);
    }
  }, [profile]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 2000);
    }
  }, [snackbarOpen]);

  const getSnackbarContent = (): React.ReactElement => {
    let content: React.ReactElement | string;
    switch (snackbarContent) {
      case SnackBarContent.email:
        content = (
          <span className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            <Typography variant="body2" align="center">
              {t('account.changeEmailSuccess')}
            </Typography>
          </span>
        );
        break;
      case SnackBarContent.password:
        content = (
          <span className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            <Typography variant="body2" align="center">
              {t('account.changePasswordSuccess')}
            </Typography>
          </span>
        );
        break;
      default:
        content = '';
        break;
    }

    return <SnackbarContent className={classes.snackbarContent} message={content} />;
  };

  const openChangeEmailDialog = () => {
    setChangeEmailDialogOpen(true);
  };

  const openChangePasswordDialog = () => {
    setChangePasswordDialogOpen(true);
  };

  const openDeleteAccountDialog = () => {
    setDeleteAccountDialogOpen(true);
  };

  const openDialog = (): React.ReactElement | null => {
    if (changeEmailDialogOpen) {
      return (
        <ChangeEmailDialog
          open={changeEmailDialogOpen}
          closeEvent={(success) => {
            if (success) {
              setSnackbarContent(SnackBarContent.email);
              setSnackbarOpen(true);
            }
            setChangeEmailDialogOpen(false);
          }}
        />
      );
    } else if (changePasswordDialogOpen) {
      return (
        <ChangePasswordDialog
          open={changePasswordDialogOpen}
          closeEvent={(success) => {
            if (success) {
              setSnackbarContent(SnackBarContent.password);
              setSnackbarOpen(true);
            }
            setChangePasswordDialogOpen(false);
          }}
        />
      );
    } else if (deleteAccountDialogOpen) {
      return (
        <DeleteAccountDialog
          open={deleteAccountDialogOpen}
          closeEvent={(success) => {
            setDeleteAccountDialogOpen(false);
          }}
        />
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Grid container className={classes.root}>
        {showLoadingAnimation ? (
          <Grid container alignItems="center" className={classes.container} justify="center">
            <Grid item xs={12} className={classes.progressContainer}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="flex-start"
            alignContent="flex-start"
            justify="center"
            className={classes.container}
          >
            {openDialog()}
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      history.push('/account/mydata/invoiceAddress');
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.invoiceAddress')}
                    content={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {profile.invoiceAddress.company}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {`${profile.invoiceAddress.firstName} ${profile.invoiceAddress.lastName}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {`${profile.invoiceAddress.street} ${profile.invoiceAddress.houseNumber}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {`${profile.invoiceAddress.zipCode} ${profile.invoiceAddress.place}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      openChangeEmailDialog();
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.emailTitle')}
                    content={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {profile.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      openChangePasswordDialog();
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.passwordTitle')}
                    content={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {'************************************'}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      history.push('/account/mydata/paymentDetails');
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.paymentDetails')}
                    content={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {profile.paymentDetails.accountHolder}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {profile.paymentDetails.IBAN}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {profile.paymentDetails.bankName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {profile.paymentDetails.BIC}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={0} className={classes.closeAccountCard}>
                  <CardContent className={classes.closeAccountCardContent}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.buttonLinkText}>
                          {t('account.closeAccount')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '0.8rem' }}>
                        <Link
                          variant="body2"
                          className={classes.closeAccountLink}
                          onClick={openDeleteAccountDialog}
                        >
                          {t('account.closeAccountLink')}
                        </Link>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '4rem' }}>
                <Typography variant="body2" className={classes.contactLinkContainer}>
                  {t('account.contactLink')}
                  <Link
                    variant="body2"
                    onClick={() => {
                      window.location.href = 'mailto:service@Snoozify.com';
                    }}
                  >
                    service@Snoozify.com
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Snackbar open={snackbarOpen}>{getSnackbarContent()}</Snackbar>
      </Grid>
    </Fragment>
  );
};

export default Main;
