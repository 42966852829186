/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

/**
 * Sign up
 */
export const SIGN_UP_PATH = '/signup';
export const THANKS_PATH = SIGN_UP_PATH + '/thanks';
export const CONFIRM_EMAIL_PATH = SIGN_UP_PATH + '/confirm';

/**
 * Login
 */
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';

/**
 * Dashboard
 */
export const DASHBOARD_PATH = '/dashboard';

/**
 * Account
 */
export const ACCOUNT_PATH = '/account';
export const MY_DATA_PATH = ACCOUNT_PATH + '/mydata';
export const MY_DATA_INVOICE_ADDRESS_PATH = MY_DATA_PATH + '/invoiceAddress';
export const MY_DATA_PAYMENT_DETAILS_PATH = MY_DATA_PATH + '/paymentDetails';

export const PLAN_PATH = ACCOUNT_PATH + '/plan';
export const PLAN_DETAIL_PATH = ACCOUNT_PATH + PLAN_PATH + '/current';
export const PLAN_SWITCH_PATH = ACCOUNT_PATH + PLAN_PATH + '/switch';
export const PLAN_PAUSE_PATH = ACCOUNT_PATH + PLAN_PATH + '/pause';

export const INVOICES_PATH = ACCOUNT_PATH + '/invoices';

/**
 * Help
 */

export const HELP_PATH = '/help';
