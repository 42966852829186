/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-08
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  FormGroup,
  CircularProgress,
  MenuItem,
  SnackbarContent,
  Typography,
  Snackbar,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as countries from 'i18n-iso-countries';
import map from 'lodash/map';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';

// Assets
import BackIcon from 'assets/img/back_button_icon.svg';

// Data models
import { Props } from './propTypes';
import { SnoozifyUser, InvoiceAddress, Salutation, CountryEntry } from 'models/user';

// Own components
import { RequestStatus, Language } from 'models/common';
import SnoozifyTextField from 'components/common/text-field';
import SnoozifyButton from 'components/common/button';
import SnoozifySelect from 'components/common/select';
import SnoozifyRadio from 'components/common/radio';

enum SnackBarContent {
  save = 'save',
  none = '',
}

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
      paddingBottom: '5rem',
      paddingLeft: '10%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '26.5rem',
      },
    },
    title: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.6rem',
      },
    },
    container: {
      height: '100%',
    },
    formControl: {
      minWidth: '100%',
    },
    formLabel: {
      fontSize: '1.6rem',
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    itemSpacing: {
      padding: '0 0 3.75rem 0',
    },
    progressContainer: {
      textAlign: 'center',
    },
    snackbarContent: {
      backgroundColor: green[600],
    },
    snackbarIcon: {
      marginRight: '1rem',
    },
    snackbarMessage: {
      backgroundColor: 'inherit',
      display: 'flex',
    },
    backButton: {
      padding: '0',
      position: 'absolute',
      left: '0',
      width: '6.4rem',
      height: '6.4rem',
      transform: 'translate(-2rem, -0.7rem)',
      top: '0rem',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      color: 'white',
      width: '100%',
      height: '100%',
    },
    iconLabel: {
      display: 'contents',
    },
  }),
);

// TODO: set country language according to language setting
const DetailInvoiceAddress: React.FC<Props> = (props) => {
  const { profile, updateUserProfile, loadUserProfile, unsubscribe, progress } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));

  const [language, setLanguage] = useState<Language | null>(null);
  const [updatedUserProfile, setUpdatedUserProfile] = useState<SnoozifyUser>(profile);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const unsubscribeRef = useRef(unsubscribe);
  const [snackbarContent] = useState<SnackBarContent>(SnackBarContent.save);

  useEffect(() => {
    setLanguage(i18n.languages[0] as Language);
  }, [i18n.language, i18n.languages]);

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  useEffect(() => {
    setUpdatedUserProfile(profile);
    setShowLoadingAnimation(false);
  }, [profile]);

  useEffect(() => {
    // set the loading animation here, but wait for the profile to be updated until rendering
    // otherwise there will be unwanted animation because the  progress can be updated before / simultaneously to profile
    // this is a problem when the redux store is still in its initial state
    // add a timeout to be failsafe
    if (progress === RequestStatus.LOADING) {
      setShowLoadingAnimation(true);
      setTimeout(() => {
        setShowLoadingAnimation(false);
      }, 2000);
    }
  }, [progress]);

  useEffect(() => {
    unsubscribeRef.current = unsubscribe;
  }, [unsubscribe]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 2000);
    }
  }, [snackbarOpen]);

  // keep unsubscribe reference inside mutable object to prevent unwanted dependeny update
  useEffect(() => {
    return () => {
      // cleanup subscription
      unsubscribeRef.current();
    };
  }, []);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 2000);
    }
  }, [snackbarOpen]);

  const returnAction = () => {
    history.goBack();
  };

  const updateAction = () => {
    updateUserProfile(updatedUserProfile);
    setSnackbarOpen(true);
    setTimeout(() => {
      returnAction();
    }, 800);
  };

  const onInputInvoiceAddress = <K extends keyof InvoiceAddress>(
    value: InvoiceAddress[K],
    field: K,
  ) => {
    let update: SnoozifyUser = {
      ...updatedUserProfile,
    };
    update.invoiceAddress[field] = value;
    setUpdatedUserProfile(update);
  };

  const getLanguageEntries = (lang: Language | null): React.ReactElement[] => {
    const countryNames = countries.getNames(lang || 'de');

    const countryEntries = map(
      countryNames,
      (name: string, iso2Code: string): CountryEntry => ({
        isoCode: countries.alpha2ToAlpha3(iso2Code),
        name,
      }),
    );

    return map(countryEntries, (entry: CountryEntry) => (
      <MenuItem key={`${entry.isoCode}-country-entry`} value={entry.isoCode}>
        {entry.name}
      </MenuItem>
    ));
  };

  const getSnackbarContent = (): React.ReactElement => {
    let content: React.ReactElement | string;
    switch (snackbarContent) {
      case SnackBarContent.save:
        content = (
          <span className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            <Typography align="center">{t('account.saveDataSuccess')}</Typography>
          </span>
        );
        break;
      default:
        content = '';
        break;
    }

    return <SnackbarContent className={classes.snackbarContent} message={content} />;
  };

  return (
    <Grid container className={classes.root} justify="center">
      {showLoadingAnimation ? null : (
        <IconButton
          className={classes.backButton}
          classes={{ label: classes.iconLabel }}
          edge="start"
          arial-label="Snoozify-logo"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={() => {
            returnAction();
          }}
        >
          <img src={BackIcon} alt="Snoozify icon with text Snoozify" className={classes.icon}></img>
        </IconButton>
      )}
      {showLoadingAnimation ? (
        <Grid container alignItems="center" className={classes.container} justify="center">
          <Grid item xs={12} className={classes.progressContainer}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="flex-start"
          alignContent="center"
          className={classes.container}
        >
          <Grid item>
            <FormGroup>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item>
                  <Grid item>
                    <Grid item style={{ paddingBottom: '6.4rem' }}>
                      <Typography align="left" variant="h2" className={classes.title}>
                        {t('account.editInvoiceAddress')}
                      </Typography>
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyRadio
                        label={t('labels.salutation')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.value in Salutation) {
                            const salutation = event.target.value as Salutation;
                            onInputInvoiceAddress(salutation, 'salutation');
                          }
                        }}
                        options={[
                          { value: Salutation.mr, localization: t('labels.mr') },
                          { value: Salutation.ms, localization: t('labels.ms') },
                          { value: Salutation.other, localization: t('labels.other') },
                        ]}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing organization"
                        label={t('labels.company')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'company')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.company}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing given-name"
                        label={t('labels.firstName')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'firstName')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.firstName}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing family-name"
                        label={t('labels.lastName')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'lastName')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.lastName}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing street-address"
                        label={t('labels.street')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'street')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.street}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        label={t('labels.houseNumber')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'houseNumber')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.houseNumber}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing address-level4"
                        label={t('labels.additionalAddress')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'additionalAddress')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.additionalAddress}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing postal-code"
                        label={t('labels.zipCode')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'zipCode')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.zipCode}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifyTextField
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing address-level2"
                        label={t('labels.place')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'place')
                        }
                        type="text"
                        value={updatedUserProfile.invoiceAddress.place}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <SnoozifySelect
                        snoozifyVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        value={updatedUserProfile.invoiceAddress.country}
                        id="country-select"
                        labelId="country-select-label"
                        label={t('labels.country')}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          const { value } = event.target as HTMLSelectElement;
                          onInputInvoiceAddress(value, 'country');
                        }}
                      >
                        {getLanguageEntries(language)}
                      </SnoozifySelect>
                    </Grid>
                    <Grid item xs={12} container style={{ paddingTop: '2.65rem' }}>
                      <span style={{ paddingRight: '2.1rem', paddingTop: '2rem' }}>
                        <SnoozifyButton primaryColor={false} onClick={returnAction}>
                          {t('common.cancel')}
                        </SnoozifyButton>
                      </span>
                      <span style={{ paddingTop: '2rem' }}>
                        <SnoozifyButton onClick={updateAction}>{t('common.save')}</SnoozifyButton>
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      )}
      <Snackbar open={snackbarOpen}>{getSnackbarContent()}</Snackbar>
    </Grid>
  );
};

export default DetailInvoiceAddress;
