/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
// Data models
import { Props } from './propTypes';
import { DeleteAccountError } from 'models/auth';
import { RequestStatus } from 'models/common';

// Own components
import SnoozifyTextField from 'components/common/text-field';
import SnoozifyOverlay from 'components/common/overlay';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      borderRadius: '0',
      padding: '4.8rem',
    },
    titleRoot: {
      justifyContent: 'center',
      padding: '0 0 2.8rem',
    },
    contentRoot: {
      justifyContent: 'center',
      padding: '4.8rem 0 0',
    },
    actionsRoot: {
      justifyContent: 'center',
      padding: '1rem 0 0',
    },
    errorMessage: {
      color: 'red',
    },
    inputField: {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '0',
      '& .MuiInputBase-root': {
        borderRadius: '0',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0',
      },
      '& .MuiInputBase-input': {
        fontWeight: 400,
      },
    },
    inputLabel: {
      display: 'none',
    },
    cancelButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
      padding: 'unset',
      fontSize: '1.5rem',
      fontWeight: 400,
      borderRadius: '1rem',
      border: `1px solid ${theme.palette.primary.dark}`,
      textDecoration: 'none',
      width: '13.6rem',
      height: '3.1rem',
      '&:hover,&:active,&:focus': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.dark,
      },
    },
    saveButton: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      padding: 'unset',
      fontSize: '1.5rem',
      fontWeight: 400,
      borderRadius: '1rem',

      textDecoration: 'none',
      width: '13.6rem',
      height: '3.1rem',
      '&:hover,&:active,&:focus': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.main,
      },
    },
    saveButtonDisabled: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
    },
    textField: {
      color: 'red',
    },
    closeButton: {
      padding: '0',
      position: 'absolute',
      right: '1.2rem',
      width: '1.6rem',
      height: '1.6rem',
      top: '1.2rem',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      color: 'white',
      width: '100%',
      height: '100%',
    },
    iconLabel: {
      display: 'contents',
    },
  }),
);

// TODO: catch case when new firebase authetication is required to change email
const DeleteAccountDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { closeEvent, onDeleteAccount, error, progress, open } = props;
  const [deleteInput, setDeleteInput] = useState<string>('');
  const [deleteKeyword] = useState<string>(t('account.deleteKeyword'));
  const classes = useStyles();

  const onInput = (value: string) => {
    setDeleteInput(value);
  };

  const validateKeyword = (): boolean => {
    return deleteKeyword === deleteInput;
  };

  const getErrorMessage = (): string => {
    switch (error) {
      case DeleteAccountError.REQUIRES_RECENT_LOGIN: {
        return t('common.newLoginRequired');
      }

      default: {
        return '';
      }
    }
  };

  const returnAction = () => {
    closeEvent(false);
  };

  const updateAction = () => {
    onDeleteAccount();
  };

  const DeleteComponent = () => <span style={{ color: 'red' }}>{t('account.deleteKeyword')} </span>;

  return (
    <SnoozifyOverlay
      open={open}
      title={t('account.deleteAccount')}
      subTitle={
        <Trans i18nKey="account.deleteAccountInstruction">
          {[<DeleteComponent key="deleteComponent" />]}
        </Trans>
      }
      cancelAction={returnAction}
      confirmAction={updateAction}
      confirmButtonText={t('common.confirm')}
      confirmButtonDisabled={!validateKeyword()}
    >
      {progress === RequestStatus.LOADING ? (
        <CircularProgress />
      ) : (
        [
          <Grid item xs={12} key="labels.email">
            <SnoozifyTextField
              required
              label={t('account.delete')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInput(event.target.value)
              }
              value={deleteInput}
              type="text"
              variant="outlined"
            />
          </Grid>,
        ]
      )}
      {progress === RequestStatus.ERROR ? (
        <Grid item xs={12}>
          <Typography className={classes.errorMessage}>{getErrorMessage()}</Typography>
        </Grid>
      ) : null}
    </SnoozifyOverlay>
  );
};

export default DeleteAccountDialog;
