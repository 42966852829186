/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-07-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { FireStoreError, FireStorePermissionCreateError } from 'models/firebase';
import { RequestStatus } from 'models/common';
import { Permission } from 'models/permission';

export enum PermissionActionType {
  SET_PERMISSIONS = '@@permissions/SET_PERMISSIONS',
  SET_INVITATION_MAIL = '@@permissions/SET_INVITATION_MAIL',
  SET_GRANTED_PERMISSIONS = '@@permissions/SET_GRANTED_PERMISSIONS',
  SET_CURRENT_WORKSPACE = '@@permissions/SET_CURRENT_WORKSPACE',
  CREATE_PERMISSIONS_STATUS = '@@permissions/CREATE_PERMISSIONS_STATUS',
  LOAD_PERMISSIONS_STATUS = '@@permissions/LOAD_PERMISSIONS_STATUS',
  UPDATE_PERMISSIONS_UNSUBSCRIBE = '@@permissions/UPDATE_PERMISSIONS_UNSUBSCRIBE',
  UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE = '@@permissions/UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE',
}

export interface SetPermissionsAction {
  permissions: Permission[];
  type: PermissionActionType.SET_PERMISSIONS;
}

export interface SetInvitationMailAction {
  email: string;
  type: PermissionActionType.SET_INVITATION_MAIL;
}

export interface SetCurrentWorkspace {
  workspaceId: string | null;
  type: PermissionActionType.SET_CURRENT_WORKSPACE;
}

export interface SetGrantedPermissionsAction {
  grantedPermissions: Permission[];
  type: PermissionActionType.SET_GRANTED_PERMISSIONS;
}

export interface LoadPermissionStatusAction {
  error: FireStoreError;
  status: RequestStatus;
  type: PermissionActionType.LOAD_PERMISSIONS_STATUS;
}

export interface CreatePermissionStatusAction {
  status: RequestStatus;
  error: FireStorePermissionCreateError;
  type: PermissionActionType.CREATE_PERMISSIONS_STATUS;
}

export interface UpdatePermissionsUnsubscribeAction {
  unsubscribe: () => void;
  type: PermissionActionType.UPDATE_PERMISSIONS_UNSUBSCRIBE;
}

export interface UpdateGrantedPermissionsUnsubscribeAction {
  unsubscribe: () => void;
  type: PermissionActionType.UPDATE_GRANTED_PERMISSIONS_UNSUBSCRIBE;
}

export type PermissionAction =
  | SetPermissionsAction
  | LoadPermissionStatusAction
  | UpdatePermissionsUnsubscribeAction
  | SetGrantedPermissionsAction
  | UpdateGrantedPermissionsUnsubscribeAction
  | SetCurrentWorkspace
  | SetInvitationMailAction
  | CreatePermissionStatusAction;
