/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-22
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Container, Grid, Hidden } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';

// Public Route component wrapper
import PublicRoute from 'hoc/PublicRoute/PublicRoute';

// View components
import DesktopHeader from 'components/header/desktop-header';
import Footer from 'components/footer';
import LoginView from 'views/login/LoginView';
import MainDrawer from 'components/nav/main-drawer';
import MobileHeader from 'components/header/mobile-header';
import SignupView from 'views/signup/SignupView';

// Data models
import { NavEntry, NavPlainEntry } from 'models/common';

// Config
import { LOGIN_PATH, SIGN_UP_PATH } from 'config/routes';

const useStyles = makeStyles<Theme>((theme) => ({
  contentWrapper: {
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${theme.spacing(12)}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.spacing(17.5)}px)`,
    },
    overflowY: 'auto',
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      minHeight: `calc(100% - ${theme.spacing(12)}px)`,
      paddingBottom: theme.spacing(17.5),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100% - ${theme.spacing(17.5)}px)`,
      paddingBottom: theme.spacing(70),
    },
  },
  footer: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
  },
  root: {
    height: '100vh',
    width: '100%',
  },
}));

const PublicView: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const navEntries: NavPlainEntry[] = [
    {
      id: 'functions',
      label: t('mainNav.functions'),
      target: 'https://Snoozify.com/functions',
      type: 'entry',
    },
    {
      id: 'pricing',
      label: t('mainNav.pricing'),
      target: 'https://Snoozify.com/pricing',
      type: 'entry',
    },
    {
      id: 'contact',
      label: t('mainNav.contact'),
      target: 'https://Snoozify.com/contact',
      type: 'entry',
    },
  ];

  const drawerEntries: NavEntry[] = [...navEntries];

  return (
    <Grid container className={classes.root} id="public-view-root">
      <MainDrawer entries={drawerEntries} />
      <Grid className={classes.header} item xs={12} id="public-header-root">
        <Hidden smUp>
          <MobileHeader />
        </Hidden>
        <Hidden xsDown>
          <DesktopHeader entries={navEntries} />
        </Hidden>
      </Grid>
      <Grid className={classes.contentWrapper} item container xs={12} id="public-content-root">
        <Container className={classes.content} maxWidth="lg">
          <Switch>
            <PublicRoute path={LOGIN_PATH} component={LoginView} />
            <Route path={SIGN_UP_PATH} component={SignupView} />
            <Route exact path="/">
              <Redirect to={LOGIN_PATH} />
            </Route>
          </Switch>
        </Container>
        <Grid className={classes.footer} item xs={12} id="public-footer-root">
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PublicView;
