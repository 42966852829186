/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Data models
import { ApplicationState } from 'models/store';
import { SnoozifyUser } from 'models/user';

// Styles
const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const Overview: FC = () => {
  const profile = useSelector<ApplicationState, SnoozifyUser>((state) => state.user.profile);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title="Dashboard | Snoozify"></Helmet>
      <Grid container alignItems="center" className={classes.root} justify="space-around">
        <Grid item xs={12}>
          <Typography align="center" variant="h2">
            {t('dashboard.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h4">
            {t('dashboard.welcome', { displayName: profile.email })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">{t('dashboard.message')}</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Overview;
