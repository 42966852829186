/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-08
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  FormControl,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import * as React from 'react';
import { FC } from 'react';
import { v1 as uuidv1 } from 'uuid';

// Assets
import { ReactComponent as RadioIcon } from 'assets/img/snoozify_radio.svg';
import { ReactComponent as RadioActiveIcon } from 'assets/img/snoozify_radio_active.svg';

// Data models
import { Props } from './propTypes';
// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    padding: '0',
    marginBottom: '-2rem',
  },
  formRoot: {},
  formControl: {
    'label + &': {
      marginTop: '0',
    },
  },
  helperText: {
    color: theme.palette.text.primary,
    padding: '0.1rem 0 0 2rem',
    maxWidth: '38rem',
  },
  warningIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  formLabel: {
    fontSize: '1.6rem',
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
  formLabelFocused: {
    color: `${theme.palette.text.primary} !important`,
  },
  radioGroup: {
    '& .MuiFormControlLabel-label': {
      paddingRight: '0.8rem',
    },
    '& .MuiRadio-root': {
      color: theme.palette.text.primary,
      padding: '2rem',
    },
    '& .MuiSvgIcon-root': {
      width: '2.8rem',
      height: '2.8rem',
    },
    '& .Mui-checked': {
      color: theme.palette.primary.dark,
    },
  },
  radioRoot: {
    '&:hover': {
      '& #Oval': {
        stroke: theme.palette.primary.dark,
      },
    },
  },
  icon: {
    width: '2.8rem',
    height: '2.8rem',
  },
}));

const SnoozifyRadio: FC<Props> = (props) => {
  const { label, options, error, helperText, ...rest } = props;
  const classes = useStyles(props);
  const inputId = uuidv1();

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} container justify="center">
        <FormControl classes={{ root: classes.formRoot }}>
          {label ? (
            <FormLabel
              classes={{ focused: classes.formLabelFocused, root: classes.formLabel }}
              component="legend"
            >
              {label}
            </FormLabel>
          ) : null}
          <RadioGroup
            aria-label="radio"
            name="radio"
            value={rest.value}
            classes={{ root: classes.radioGroup }}
            onChange={rest.onChange}
            row
            {...rest}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.localization + '-key'}
                value={option.value}
                className={classes.radioRoot}
                control={
                  <Radio
                    icon={<RadioIcon className={classes.icon} />}
                    checkedIcon={<RadioActiveIcon className={classes.icon} />}
                  />
                }
                label={option.localization}
              />
            ))}
          </RadioGroup>
          <FormHelperText
            error={error}
            classes={{ root: classes.helperText }}
            id={`${inputId}-helper-text`}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {error ? <WarningTwoToneIcon className={classes.warningIcon} /> : null}
              <span style={{ padding: '0 0 0 0.5rem' }}>{helperText}</span>
            </span>
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

SnoozifyRadio.defaultProps = {
  options: [],
  error: false,
  helperText: '',
  label: '',
};

export default SnoozifyRadio;
