/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

import { combineReducers } from '@reduxjs/toolkit';

import subscriptionSlice from './subscriptionSlice';
import priceSlice from './priceSlice';
import methodSlice from './methodSlice';

export default combineReducers({
  subscription: subscriptionSlice,
  price: priceSlice,
  method: methodSlice,
});
