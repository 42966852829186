/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from 'models/store';
import * as firebase from 'firebase/app';
import moment from 'moment';

// Utils
import { parseString, parseSalutation } from 'utils/parsingHelper';

// Data models
import { RequestStatus } from 'models/common';
import { FireStoreError } from 'models/firebase';
import { SnoozifyUser, Salutation } from 'models/user';
import {
  LoadUserStatusAction,
  UserActionType,
  UserProfileAction,
  UpdateUserProfileUnsubscribeAction,
  SetManagedUserProfileAction,
} from 'models/user/actions';
import { UserDocument } from 'models/firebase/user';

export const updateLoadUserStatus = (
  status: RequestStatus,
  error: FireStoreError = FireStoreError.NONE,
): LoadUserStatusAction => ({
  error,
  status,
  type: UserActionType.LOAD_USER_STATUS,
});

export const saveUpdateUserUnsubscribe = (
  unsubscribe: () => void,
): UpdateUserProfileUnsubscribeAction => ({
  unsubscribe,
  type: UserActionType.UPDATE_USER_PROFILE_UNSUBSCRIBE,
});

export const updateUser = (profile: SnoozifyUser, userId: string = '') => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
  ): Promise<void> => {
    try {
      const userData: UserDocument = {
        createdAt: firebase.firestore.Timestamp.fromDate(profile.createdAt.toDate()),
        email: profile.email,
        initialLogin: profile.initialLogin,
        privacyAcceptedAt: firebase.firestore.Timestamp.fromDate(
          profile.privacyAcceptedAt.toDate(),
        ),
        profileInfoUpdated: firebase.firestore.Timestamp.fromDate(
          profile.profileInfoUpdated.toDate(),
        ),
        accountType: profile.accountType,
        termsAcceptedAt: firebase.firestore.Timestamp.fromDate(profile.termsAcceptedAt.toDate()),
        invoiceAddress: {
          company: profile.invoiceAddress?.company,
          salutation: profile.invoiceAddress?.salutation,
          firstName: profile.invoiceAddress?.firstName,
          lastName: profile.invoiceAddress?.lastName,
          additionalAddress: profile.invoiceAddress?.additionalAddress,
          street: profile.invoiceAddress?.street,
          houseNumber: profile.invoiceAddress?.houseNumber,
          zipCode: profile.invoiceAddress?.zipCode,
          place: profile.invoiceAddress?.place,
          country: profile.invoiceAddress?.country,
        },
        paymentDetails: {
          IBAN: profile.paymentDetails?.IBAN,
          bankName: profile.paymentDetails?.bankName,
          accountHolder: profile.paymentDetails?.accountHolder,
          BIC: profile.paymentDetails?.BIC,
          customerId: profile.paymentDetails?.customerId,
        },
      };

      if (userId || profile.uid) {
        await firebase
          .firestore()
          .collection('/versions/v1/users')
          .doc(userId !== '' ? userId : profile.uid)
          .set(userData);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateManagedUserState = (
  profile: SnoozifyUser | null,
): SetManagedUserProfileAction => ({
  profile,
  type: UserActionType.SET_MANAGED_USER_PROFILE,
});

export const updateUserState = (profile: SnoozifyUser): UserProfileAction => ({
  profile,
  type: UserActionType.USER_PROFILE,
});

export const loadUser = () => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, {}, ApplicationAction>,
  ): Promise<void> => {
    dispatch(updateLoadUserStatus(RequestStatus.LOADING));
    try {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const userProfileUnsubscribe = firebase
          .firestore()
          .doc(`/versions/v1/users/${currentUser.uid}`)
          .onSnapshot(
            (snapshot: firebase.firestore.DocumentSnapshot) => {
              if (!snapshot.exists) {
                return;
              }
              // Add event observer for event changes
              const user = snapshot.data() as UserDocument;

              const profile: SnoozifyUser = {
                profileInfoUpdated: user.profileInfoUpdated
                  ? moment(user.profileInfoUpdated.toDate())
                  : moment(),
                createdAt: moment(user.createdAt.toDate()),
                email: user.email,
                initialLogin: user.initialLogin,
                privacyAcceptedAt: moment(user.privacyAcceptedAt.toDate()),
                termsAcceptedAt: moment(user.termsAcceptedAt.toDate()),
                accountType: user.accountType,
                uid: currentUser.uid,
                invoiceAddress: {
                  company: user.invoiceAddress ? parseString(user.invoiceAddress.company) : '',
                  salutation: user.invoiceAddress
                    ? parseSalutation(user.invoiceAddress.salutation)
                    : Salutation.none,
                  firstName: user.invoiceAddress ? parseString(user.invoiceAddress.firstName) : '',
                  lastName: user.invoiceAddress ? parseString(user.invoiceAddress.lastName) : '',
                  additionalAddress: user.invoiceAddress
                    ? parseString(user.invoiceAddress.additionalAddress)
                    : '',
                  street: user.invoiceAddress ? parseString(user.invoiceAddress.street) : '',
                  houseNumber: user.invoiceAddress
                    ? parseString(user.invoiceAddress.houseNumber)
                    : '',
                  zipCode: user.invoiceAddress ? parseString(user.invoiceAddress.zipCode) : '',
                  place: user.invoiceAddress ? parseString(user.invoiceAddress.place) : '',
                  country: user.invoiceAddress ? parseString(user.invoiceAddress.country) : '',
                },
                paymentDetails: {
                  IBAN: user.paymentDetails ? parseString(user.paymentDetails.IBAN) : '',
                  bankName: user.paymentDetails ? parseString(user.paymentDetails.bankName) : '',
                  accountHolder: user.paymentDetails
                    ? parseString(user.paymentDetails.accountHolder)
                    : '',
                  BIC: user.paymentDetails ? parseString(user.paymentDetails.BIC) : '',
                  customerId: user.paymentDetails
                    ? parseString(user.paymentDetails.customerId)
                    : '',
                },
              };
              dispatch(updateUserState(profile));
              dispatch(updateLoadUserStatus(RequestStatus.SUCCESS));
            },
            function(error: Error) {
              console.log(error);
            },
          );
        dispatch(saveUpdateUserUnsubscribe(userProfileUnsubscribe));
      }
    } catch (error) {
      dispatch(updateLoadUserStatus(RequestStatus.ERROR, error.code as FireStoreError));
    } finally {
      setTimeout(() => {
        dispatch(updateLoadUserStatus(RequestStatus.IDLE));
      }, 2000);
    }
  };
};
