/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { regExpEmail } from 'utils/inputData';

// Data models
import { Props } from './propTypes';
import { RequestStatus } from 'models/common';
import { ChangeEmailError } from 'models/auth';

// Own components
import SnoozifyTextField from 'components/common/text-field';
import SnoozifyOverlay from 'components/common/overlay';

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      borderRadius: '0',
      padding: '4.8rem',
    },
    titleRoot: {
      justifyContent: 'center',
      padding: '0 0 2.8rem',
    },
    contentRoot: {
      justifyContent: 'center',
      padding: '4.8rem 0 0',
    },
    actionsRoot: {
      justifyContent: 'center',
      padding: '1rem 0 0',
    },
    errorMessage: {
      color: 'red',
    },
    inputField: {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '0',
      '& .MuiInputBase-root': {
        borderRadius: '0',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0',
      },
      '& .MuiInputBase-input': {
        fontWeight: 400,
      },
    },
    inputLabel: {
      display: 'none',
    },
    cancelButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
      padding: 'unset',
      fontSize: '1.5rem',
      fontWeight: 400,
      borderRadius: '1rem',
      border: `1px solid ${theme.palette.primary.dark}`,
      textDecoration: 'none',
      width: '13.6rem',
      height: '3.1rem',
      '&:hover,&:active,&:focus': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.dark,
      },
    },
    saveButton: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      padding: 'unset',
      fontSize: '1.5rem',
      fontWeight: 400,
      borderRadius: '1rem',

      textDecoration: 'none',
      width: '13.6rem',
      height: '3.1rem',
      '&:hover,&:active,&:focus': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.main,
      },
    },
    saveButtonDisabled: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
    },
    closeButton: {
      padding: '0',
      position: 'absolute',
      right: '1.2rem',
      width: '1.6rem',
      height: '1.6rem',
      top: '1.2rem',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      color: 'white',
      width: '100%',
      height: '100%',
    },
    iconLabel: {
      display: 'contents',
    },
  }),
);

// TODO: catch case when new firebase authetication is required to change email
const ChangeEmailDialog: React.FC<Props> = (props) => {
  const { onChangeEmail, closeEvent, error, progress, open } = props;
  const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
  const [newEmail, setNewEmail] = useState<string>('');
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (progress === RequestStatus.SUCCESS) {
      closeEvent(true);
    }
  }, [closeEvent, progress]);

  const onInput = (value: string) => {
    verifyEmail(value);
    setNewEmail(value);
  };

  const verifyEmail = (email: string) => {
    setEmailIsValid(regExpEmail.test(email));
  };

  const returnAction = () => {
    closeEvent(false);
  };

  const updateAction = () => {
    onChangeEmail(newEmail);
  };

  const getErrorMessage = (): string => {
    switch (error) {
      case ChangeEmailError.EMAIL_ALREADY_IN_USE: {
        return t('signUp.emailAlreadyInUse');
      }
      case ChangeEmailError.INVALID_EMAIL: {
        return t('common.emailInvalid');
      }
      case ChangeEmailError.REQUIRES_RECENT_LOGIN: {
        return t('common.newLoginRequired');
      }

      default: {
        return '';
      }
    }
  };

  return (
    <SnoozifyOverlay
      open={open}
      title={t('account.changeEmail')}
      subTitle={t('account.enterNewEmail')}
      cancelAction={returnAction}
      confirmAction={updateAction}
      confirmButtonDisabled={!emailIsValid || progress === RequestStatus.LOADING || newEmail === ''}
    >
      {progress === RequestStatus.LOADING ? (
        <CircularProgress />
      ) : (
        [
          <Grid item xs={12} key="labels.email">
            <SnoozifyTextField
              required
              error={!emailIsValid}
              autoComplete="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInput(event.target.value)
              }
              value={newEmail}
              label={t('labels.email')}
              type="email"
              variant="outlined"
              helperText={emailIsValid ? '' : t('common.emailInvalid')}
            />
          </Grid>,
        ]
      )}
      {progress === RequestStatus.ERROR ? (
        <Grid item xs={12}>
          <Typography className={classes.errorMessage}>{getErrorMessage()}</Typography>
        </Grid>
      ) : null}
    </SnoozifyOverlay>
  );
};

export default ChangeEmailDialog;
