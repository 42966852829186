/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import 'firebase/auth';

// Data models
import { RequestStatus } from 'models/common';
import {
  LoginData,
  LoginError,
  RequestPasswordResetData,
  RequestPasswordResetError,
  ResetPasswordError,
} from 'models/login';
import {
  LoginActionType,
  LoginDataAction,
  LoginStatusAction,
  RequestResetDataAction,
  RequestResetStatusAction,
  ResetPasswordStatusAction,
  LoginDataResetAction,
} from 'models/login/actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ApplicationAction } from 'models/store';

export const updateLoginData = (data: LoginData): LoginDataAction => ({
  data,
  type: LoginActionType.DATA,
});

export const resetLoginData = (): LoginDataResetAction => ({
  type: LoginActionType.DATA_RESET,
});

export const updateLoginStatus = (
  status: RequestStatus,
  error: LoginError = LoginError.NONE,
): LoginStatusAction => ({
  error,
  status,
  type: LoginActionType.LOGIN_STATUS,
});

export const login = (data: LoginData): ThunkAction<Promise<void>, {}, {}, ApplicationAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch(updateLoginStatus(RequestStatus.LOADING, LoginError.NONE));
    try {
      const persistence = data.remember
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION;
      firebase.auth().setPersistence(persistence);
      await firebase.auth().signInWithEmailAndPassword(data.email, data.password);

      dispatch(updateLoginStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(updateLoginStatus(RequestStatus.ERROR, error.code));
    }
  };
};

export const updateRequestResetData = (data: RequestPasswordResetData): RequestResetDataAction => ({
  data,
  type: LoginActionType.REQUEST_RESET_DATA,
});

export const updateRequestResetStatus = (
  status: RequestStatus,
  error: RequestPasswordResetError,
): RequestResetStatusAction => ({
  error,
  status,
  type: LoginActionType.REQUEST_RESET_STATUS,
});

export const updateResetStatus = (
  status: RequestStatus,
  error: ResetPasswordError,
): ResetPasswordStatusAction => ({
  error,
  status,
  type: LoginActionType.RESET_STATUS,
});
