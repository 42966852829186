/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, FormControl, InputLabel, FormHelperText, Select } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import * as React from 'react';
import { FC } from 'react';
import { v1 as uuidv1 } from 'uuid';

import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

// Data models
import { Props } from './propTypes';
// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    padding: '0',
  },
  formControl: {
    'label + &': {
      marginTop: '0',
    },
  },
  label: {
    fontSize: '1.2rem',
    color: `${theme.palette.text.primary} !important`,
    padding: '0 0 0.8rem 2rem',
    position: 'absolute',
    transform: 'translate(0, -2rem)',
  },
  textRoot: {
    padding: '0',
    height: '4rem',
    width: (props) =>
      props.snoozifyVariant === 'small'
        ? '18.4rem'
        : props.snoozifyVariant === 'medium'
        ? '29rem'
        : '40rem',
    '& :hover': {
      border: '0',
    },
  },
  input: {
    height: '0',
    padding: '2rem 2rem',
    fontSize: '1.6rem',
    lineHeight: '2.1rem',
  },
  underline: {
    '&:hover:not(.Mui-disabled):before': {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
      backgroundColor: theme.palette.primary.contrastText,
    },
    '&:after': {
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
      backgroundColor: theme.palette.primary.contrastText,
    },
    '&:before': {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  inputError: {
    '&:after': {
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
    },
  },
  helperText: {
    color: theme.palette.text.primary,
    padding: '0.1rem 0 0 2rem',
    maxWidth: '38rem',
  },

  popperRoot: {
    backgroundColor: theme.palette.text.primary,
  },
  infoButton: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  infoIconLabel: {
    display: 'contents',
    '& svg': {
      fill: theme.palette.text.primary,
      width: '1.6rem',
      height: '1.6rem',
    },
  },
  closeButton: {
    padding: '0',
    position: 'absolute',
    right: '1.2rem',
    width: '1rem',
    height: '1rem',
    top: '1.2rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconLabel: {
    display: 'contents',
    '& svg': {
      fill: theme.palette.primary.contrastText,
      width: '1.5rem',
      height: '1.5rem',
    },
  },

  paper: {
    maxWidth: '27.1rem',
    overflow: 'auto',
    backgroundColor: theme.palette.text.primary,
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.text.primary} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.text.primary} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.text.primary} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.text.primary}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      borderColor: theme.palette.text.primary,
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  dialogContent: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.2rem',
    paddingTop: '2rem',
  },
  warningIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  selectRoot: {
    padding: '2rem 2rem',
    fontSize: '1.6rem',
    lineHeight: '0rem',
    height: '0rem',
  },
  selectMenu: {
    textAlign: 'left',
  },
  select: {
    width: (props) =>
      props.snoozifyVariant === 'small'
        ? '18.4rem'
        : props.snoozifyVariant === 'medium'
        ? '29rem'
        : '40rem',
    backgroundColor: theme.palette.primary.contrastText,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.primary}`,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset',
    },
  },
}));

const SnoozifySelect: FC<Props> = (props) => {
  const { snoozifyVariant, popperText, children, ...rest } = props;
  const classes = useStyles(props);
  const inputId = uuidv1();
  //const inputProps: InputProps = rest as InputProps;

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} container justify="center" style={{ boxSizing: 'content-box' }}>
        <FormControl classes={{ root: classes.formRoot }}>
          <InputLabel
            className={classes.label}
            variant="standard"
            shrink
            id={inputId}
            required={rest.required}
          >
            {rest.label}
          </InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            variant="outlined"
            value={rest.value}
            onChange={rest.onChange}
            className={classes.select}
            classes={{
              root: classes.selectRoot,
              selectMenu: classes.selectMenu,
            }}
          >
            {children}
          </Select>
          <FormHelperText
            error={rest.error}
            classes={{ root: classes.helperText }}
            id={`${inputId}-helper-text`}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {rest.error ? <WarningTwoToneIcon className={classes.warningIcon} /> : null}
              <span style={{ padding: '0 0 0 0.5rem' }}>{rest.helperText}</span>
            </span>
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

SnoozifySelect.defaultProps = {
  snoozifyVariant: 'large',
};

export default SnoozifySelect;
