/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

// Action creators
import { changePassword } from 'store/auth/authSlice';

// Presentational component
import ChangePasswordDialog from './ChangePasswordDialog';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { RootState, AppDispatch } from 'store';

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (state) => ({
  error: state.auth.changePasswordError,
  progress: state.auth.changePasswordState,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (dispatch: AppDispatch) => ({
  onChangePassword: (password) => {
    dispatch(changePassword({ newPassword: password }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordDialog);
