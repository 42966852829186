/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethod } from '@stripe/stripe-js';
import { Stripe } from 'stripe';

// Data models
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';
import { getJsonHeaders, postJsonHeaders } from 'utils/requestHeaders';

// Action creator
import { getTargetProfile } from 'utils/user/userUtils';

// Utils
import { createAppThunk } from 'utils/appAction';

const sliceName = '@@payment/methods';

export type PaymentMethodState = {
  addMethodModalOpen: boolean;
  changeMethodStatus: RequestStatus;
  createCustomerStatus: RequestStatus;
  createMethodStatus: RequestStatus;
  defaultMethodId: string;
  deleteMethodStatus: RequestStatus;
  fetchCustomerStatus: RequestStatus;
  fetchMethodsStatus: RequestStatus;
  paymentMethods: Array<Stripe.PaymentMethod>;
};

export type UpdateMethodsPayload = {
  methods: Array<Stripe.PaymentMethod>;
};

export type UpdateDefaultMethodPayload = {
  methodId: string;
};

export const initialState: PaymentMethodState = {
  addMethodModalOpen: false,
  changeMethodStatus: RequestStatus.IDLE,
  createCustomerStatus: RequestStatus.IDLE,
  createMethodStatus: RequestStatus.IDLE,
  deleteMethodStatus: RequestStatus.IDLE,
  defaultMethodId: '',
  fetchCustomerStatus: RequestStatus.IDLE,
  fetchMethodsStatus: RequestStatus.IDLE,
  paymentMethods: [],
};

export const getPaymentMethods = createAppThunk(
  sliceName + '/getPaymentMethods',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;

      if (customerId && customerId !== '') {
        const response = await fetch(
          `${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}/methods`,
          {
            headers: getJsonHeaders,
          },
        );
        const methods = await response.json();
        dispatch(updateMethods({ methods }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const createCustomer = createAppThunk(
  sliceName + '/createCustomer',
  async (_, { getState, rejectWithValue }) => {
    try {
      const body: { customer: Stripe.CustomerCreateParams; uid: string } = {
        customer: {
          address: {
            line1: `${getTargetProfile(getState().user)?.invoiceAddress.street ||
              ''} ${getTargetProfile(getState().user)?.invoiceAddress.houseNumber || ''}`,
            city: `${getTargetProfile(getState().user)?.invoiceAddress.place || undefined}`,
            country: `${getTargetProfile(getState().user)?.invoiceAddress.country || undefined}`,
            postal_code: `${getTargetProfile(getState().user)?.invoiceAddress.zipCode ||
              undefined}`,
          },
          email: getTargetProfile(getState().user)?.email || '',
          description: `Created by ${getState().user.profile.email}`,
        },
        uid: getTargetProfile(getState().user)?.uid || getState().user.profile.uid,
      };
      const response = await fetch(`${BACKEND_URL}/snoozifyApp/payment/customers`, {
        method: 'POST',
        headers: postJsonHeaders,
        body: JSON.stringify(body),
      });
      const customerResponse = await response.json();
      return customerResponse.customerId;
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const createPaymentMethod = createAppThunk<
  void,
  { customerId: string; method: PaymentMethod; newDefault?: boolean }
>(
  sliceName + '/createPaymentMethod',
  async ({ customerId, method, newDefault = false }, { getState, rejectWithValue }) => {
    try {
      // Attach the payment method to the customer
      await fetch(`${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}/methods`, {
        method: 'POST',
        headers: postJsonHeaders,
        body: JSON.stringify({
          method,
          newDefault: getState().payment.method.paymentMethods.length <= 0 || newDefault,
        }),
      });
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const setNewDefaultMethod = createAppThunk<void, { methodId: string }>(
  sliceName + '/setNewDefaultMethod',
  async ({ methodId }, { dispatch, getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;

    if (customerId && customerId !== '') {
      const body: Stripe.CustomerUpdateParams = {
        invoice_settings: {
          default_payment_method: methodId,
        },
      };
      try {
        const updated: Stripe.Customer = await (
          await fetch(`${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}`, {
            method: 'PUT',
            headers: postJsonHeaders,
            body: JSON.stringify(body),
          })
        ).json();
        dispatch(
          updateDefaultMethodId({
            methodId: (updated.invoice_settings.default_payment_method as string) || '',
          }),
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const fetchDefaultMethod = createAppThunk(
  sliceName + '/fetchDefaultMethod',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;

    if (customerId && customerId !== '') {
      try {
        const customer: Stripe.Customer = await (
          await fetch(`${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}`)
        ).json();
        dispatch(
          updateDefaultMethodId({
            methodId: (customer.invoice_settings.default_payment_method as string) || '',
          }),
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const changePaymentMethod = createAppThunk<void, { update: Stripe.PaymentMethod }>(
  sliceName + '/changePaymentMethod',
  async ({ update }, { getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;
    if (customerId) {
      const methodId = update.id;

      try {
        await fetch(
          `${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}/methods/${methodId}`,
          {
            method: 'PUT',
            headers: postJsonHeaders,
            body: JSON.stringify(update),
          },
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const deletePaymentMethod = createAppThunk<void, { methodId: string }>(
  sliceName + '/deletePaymentMethod',
  async ({ methodId }, { getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;
    if (customerId) {
      try {
        await fetch(
          `${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}/methods/${methodId}`,
          {
            method: 'DELETE',
          },
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

const paymentMethodSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    openAddMethodDialog(state) {
      state.addMethodModalOpen = true;
    },
    closeAddMethodModal(state) {
      state.addMethodModalOpen = false;
    },
    updateDefaultMethodId(state, action: PayloadAction<UpdateDefaultMethodPayload>) {
      state.defaultMethodId = action.payload.methodId;
    },
    updateMethods(state, action: PayloadAction<UpdateMethodsPayload>) {
      state.paymentMethods = action.payload.methods;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethods.pending, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.LOADING;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.IDLE;
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.fetchMethodsStatus = RequestStatus.ERROR;
    });

    builder.addCase(createCustomer.pending, (state, _) => {
      state.createCustomerStatus = RequestStatus.LOADING;
    });
    builder.addCase(createCustomer.fulfilled, (state, _) => {
      state.createCustomerStatus = RequestStatus.IDLE;
    });
    builder.addCase(createCustomer.rejected, (state, action) => {
      state.createCustomerStatus = RequestStatus.ERROR;
    });

    builder.addCase(createPaymentMethod.pending, (state, _) => {
      state.createMethodStatus = RequestStatus.LOADING;
    });
    builder.addCase(createPaymentMethod.fulfilled, (state, _) => {
      state.createMethodStatus = RequestStatus.IDLE;
    });
    builder.addCase(createPaymentMethod.rejected, (state, action) => {
      state.createMethodStatus = RequestStatus.ERROR;
    });

    builder.addCase(setNewDefaultMethod.pending, (state, _) => {
      state.changeMethodStatus = RequestStatus.LOADING;
    });
    builder.addCase(setNewDefaultMethod.fulfilled, (state, _) => {
      state.changeMethodStatus = RequestStatus.IDLE;
    });
    builder.addCase(setNewDefaultMethod.rejected, (state, action) => {
      state.changeMethodStatus = RequestStatus.ERROR;
    });

    builder.addCase(fetchDefaultMethod.pending, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.LOADING;
    });
    builder.addCase(fetchDefaultMethod.fulfilled, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.IDLE;
    });
    builder.addCase(fetchDefaultMethod.rejected, (state, action) => {
      state.fetchMethodsStatus = RequestStatus.ERROR;
    });

    builder.addCase(changePaymentMethod.pending, (state, _) => {
      state.changeMethodStatus = RequestStatus.LOADING;
    });
    builder.addCase(changePaymentMethod.fulfilled, (state, _) => {
      state.changeMethodStatus = RequestStatus.IDLE;
    });
    builder.addCase(changePaymentMethod.rejected, (state, action) => {
      state.changeMethodStatus = RequestStatus.ERROR;
    });

    builder.addCase(deletePaymentMethod.pending, (state, _) => {
      state.deleteMethodStatus = RequestStatus.LOADING;
    });
    builder.addCase(deletePaymentMethod.fulfilled, (state, _) => {
      state.deleteMethodStatus = RequestStatus.IDLE;
    });
    builder.addCase(deletePaymentMethod.rejected, (state, action) => {
      state.deleteMethodStatus = RequestStatus.ERROR;
    });
  },
});

export const {
  closeAddMethodModal,
  openAddMethodDialog,
  updateDefaultMethodId,
  updateMethods,
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
