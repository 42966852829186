/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Container, Grid, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  logo: {
    color: green[700],
    fontSize: theme.typography.fontSize * 20,
  },
  root: {
    height: '100%',
  },
}));

const Thanks: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title={`${t('signUp.thanksTitle')} | Snoozify`}></Helmet>
      <Container className={classes.root} maxWidth="sm">
        <Grid className={classes.root} container alignItems="center" justify="center" spacing={3}>
          <Grid item xs={12}>
            <Typography align="center" variant="h4">
              {t('signUp.thankYouMessage')}
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="center">
            <CheckCircleIcon className={classes.logo} textAnchor="center" />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">{t('signUp.afterSignUpAdvice')}</Typography>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Thanks;
