/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { Language } from '.';

export enum CommonActionType {
  LANGUAGE_CHANGE = '@@common/LANGUAGE_CHANGE',
  TOGGLE_DRAWER = '@@common/TOGGLE_DRAWER',
}

export interface LanguageChangeAction {
  language: Language;
  type: CommonActionType.LANGUAGE_CHANGE;
}

export interface ToggleDrawerAction {
  open: boolean;
  type: CommonActionType.TOGGLE_DRAWER;
}

export type CommonAction = LanguageChangeAction | ToggleDrawerAction;
