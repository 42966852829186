/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Moment } from 'moment';

export interface CountryEntry {
  isoCode: string;
  name: string;
}

export type AccountType = 'primary' | 'sub';

export interface SnoozifyUser {
  createdAt: Moment;
  email: string;
  initialLogin: boolean;
  privacyAcceptedAt: Moment;
  termsAcceptedAt: Moment;
  uid: string;
  accountType: AccountType;
  invoiceAddress: InvoiceAddress;
  paymentDetails: PaymentDetailModel;
  profileInfoUpdated: Moment;
}

export enum Salutation {
  ms = 'ms',
  mr = 'mr',
  other = 'other',
  none = '',
}

export interface InvoiceAddress {
  company: string;
  salutation: Salutation;
  firstName: string;
  lastName: string;
  additionalAddress: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  place: string;
  country: string;
}

export interface PaymentDetailModel {
  IBAN: string;
  bankName: string;
  accountHolder: string;
  BIC: string;
  customerId: string;
}
