/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import * as React from 'react';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { calculatePasswordStrength } from 'utils/password';

// Own Components
import SnoozifyTextField from '../text-field';

// Data models
import { Props } from './propTypes';
import { PasswordStrength } from 'models/auth';

const PasswordField: FC<Props> = (props) => {
  const { checkPassword, InputProps, helperText, ...rest } = props;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  /**
   * Switch visible flag of password on show / hide password button clicks
   */
  const handleToggleVisible = () => {
    setVisible(!visible);
  };

  /**
   * Returns the localized text for current password strength evaluation or an empty string if
   * password could not be evaluated, e.g. if password is empty.
   *
   * @returns - Localized strength text
   */
  const getStrengthText = (): string => {
    switch (calculatePasswordStrength(rest.value)) {
      case PasswordStrength.WEAK: {
        return t('signUp.passwordWeak');
      }
      case PasswordStrength.MEDIUM: {
        return t('signUp.passwordOk');
      }
      case PasswordStrength.STRONG: {
        return t('signUp.passwordStrong');
      }
      default: {
        return '';
      }
    }
  };

  const getStrengthDisplay = (): 'default' | 'status' | 'valid' => {
    if (rest.error || !checkPassword) {
      return 'default';
    }
    switch (calculatePasswordStrength(rest.value)) {
      case PasswordStrength.WEAK: {
        return 'default';
      }
      case PasswordStrength.MEDIUM: {
        return 'status';
      }
      case PasswordStrength.STRONG: {
        return 'valid';
      }
      default: {
        return 'default';
      }
    }
  };

  /**
   * Checks whether the password is a valid value and has a minimum strength of medium (matching
   * at least 2 criteria of security including minimum length).
   *
   * @returns - Password is valid or not
   */
  const isValid = (): boolean => {
    if (!checkPassword || typeof rest.value !== 'string' || rest.value === '') return true;
    return [PasswordStrength.MEDIUM, PasswordStrength.STRONG].includes(
      calculatePasswordStrength(rest.value),
    );
  };
  return (
    <Fragment>
      <SnoozifyTextField
        {...rest}
        variant="outlined"
        icon={visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        error={rest.error || !isValid()}
        helperText={checkPassword ? (rest.value !== '' ? getStrengthText() : '') : helperText}
        display={getStrengthDisplay()}
        onIconClick={handleToggleVisible}
        type={visible ? 'text' : 'password'}
      />
    </Fragment>
  );
};

PasswordField.defaultProps = {
  checkPassword: false,
};

export default PasswordField;
