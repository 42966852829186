/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third party

// environment
import { BACKEND_URL } from 'config/env';

// Models
import { AuthPayload } from 'models/firebase/functions';

export const postSessionRequest = (
  idToken: string,
): Promise<{ message: string; payload: AuthPayload }> => {
  return fetch(BACKEND_URL + '/SnoozifyApp/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
