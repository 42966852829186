/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { CssBaseline } from '@material-ui/core';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Private Route
import PrivateRoute from 'hoc/PrivateRoute/PrivateRouteContainer';

// View components
import Overview from 'components/dashboard/overview';

// Constants
import { DASHBOARD_PATH } from 'config/routes';

const DashboardView: FC = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Switch>
        <PrivateRoute path={DASHBOARD_PATH} component={Overview} />
        <Route>
          <Redirect to={DASHBOARD_PATH} />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default DashboardView;
