/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-02-19
 * @author Tim Lange <tl@systl.de>
 */

import { Salutation } from 'models/user';

export const parseString = (input: string): string => {
  let returnValue = input;
  if (returnValue === undefined) {
    returnValue = '';
  }

  return returnValue;
};

export const parseSalutation = (input: string): Salutation => {
  if (input in Salutation) {
    const salutation = input as Salutation;
    return salutation;
  } else {
    return Salutation.none;
  }
};
