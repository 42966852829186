/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { AppBar, Button, Container, Toolbar, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

// Assets
import Logo from 'assets/img/snoozify_logo_light_icon.svg';

// Config
import { DASHBOARD_PATH } from 'config/routes';

// Data models
import { NavEntry } from 'models/common';

// Props
interface Props {
  ctaButton?: JSX.Element;
  entries: NavEntry[];
  menu?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.background.default}`,
    boxShadow: 'none',
    height: theme.spacing(17.5),
  },
  ctaButtonRoot: {
    marginRight: theme.spacing(3),
  },
  logo: {
    height: theme.spacing(10),
  },
  logoButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingBottom: theme.spacing(4.75),
    paddingTop: theme.spacing(4.75),
  },
  mainContent: {
    flexGrow: 1,
    textAlign: 'right',
  },
  mainContentContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  navEntry: {
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',
    },
    fontWeight: 'bold',
    marginRight: theme.spacing(4.5),
    textTransform: 'none',
  },
  toolBar: {
    minHeight: 0,
  },
}));

const DesktopHeader: FC<Props> = (props) => {
  const { ctaButton, entries, menu } = props;
  const history = useHistory();
  const classes = useStyles();
  const mainNavEntries = entries.map((entry) => {
    switch (entry.type) {
      case 'entry': {
        return (
          <Button
            className={classes.navEntry}
            key={`main-nav-entry-${entry.id}`}
            onClick={() => {
              history.push(entry.target);
            }}
          >
            {entry.label}
          </Button>
        );
      }
      case 'action': {
        return (
          <Button
            className={classes.navEntry}
            key={`main-nav-entry-${entry.id}`}
            onClick={() => {
              entry.action();
            }}
          >
            {entry.label}
          </Button>
        );
      }
      default: {
        return <span />;
      }
    }
  });
  return (
    <AppBar color="inherit" className={classes.appBar} position="static">
      <Toolbar className={classes.toolBar} color="white">
        <Container maxWidth="md" className={classes.mainContentContainer}>
          <IconButton
            className={classes.logoButton}
            edge="start"
            arial-label="Snoozify-logo"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              history.push(DASHBOARD_PATH);
            }}
          >
            <img src={Logo} alt="Snoozify icon with text Snoozify" className={classes.logo}></img>
          </IconButton>
          <div className={classes.mainContent}>{mainNavEntries}</div>
          <div className={classes.ctaButtonRoot}>{ctaButton}</div>
          <div>{menu}</div>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

DesktopHeader.defaultProps = {
  ctaButton: <span />,
  menu: <span />,
};
export default DesktopHeader;
