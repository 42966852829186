/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackendLoader, { BackendOptions } from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

// Models
import { Language } from 'models/common';

// Options for lazy loading from server
const backendOptions: BackendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

const i18nInstance = i18n.createInstance({
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lng',
  },
  fallbackNS: ['translation', 'configurator', 'user'],
  defaultNS: 'translation',
  backend: backendOptions,
  fallbackLng: Language.DE,
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV === 'development',
  load: 'languageOnly',
});

i18nInstance
  .use(LanguageDetector)
  .use(BackendLoader)
  .use(initReactI18next)
  .init();

export default i18nInstance;
