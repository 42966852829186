/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import * as React from 'react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Assets
import LogoIcon from 'assets/img/snoozify_logo_light_icon.svg';

// Config
import { DASHBOARD_PATH } from 'config/routes';

// Action creators
import { toggleDrawer } from 'store/common/actions';

// Data models
import { ApplicationState } from 'models/store';

// Props
interface Props {
  ctaButton?: JSX.Element;
}

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.background.default}`,
    boxShadow: 'none',
    height: theme.spacing(12),
  },
  ctaButtonContainer: {
    flexGrow: 1,
    textAlign: 'center',
  },
  logo: {
    width: theme.spacing(8),
  },
  logoButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: theme.spacing(2),
  },
  menuButton: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    padding: theme.spacing(2),
  },
  toolBar: {
    minHeight: 0,
  },
}));
export const MobileHeader: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector<ApplicationState>((state) => state.common.drawerOpen);
  const { ctaButton } = props;
  const history = useHistory();
  const classes = useStyles();
  return (
    <AppBar color="inherit" className={classes.appBar} position="static">
      <Toolbar className={classes.toolBar} color="white">
        <IconButton
          edge="end"
          aria-label="Snoozify-logo"
          className={classes.logoButton}
          onClick={() => {
            history.push(DASHBOARD_PATH);
          }}
        >
          <img src={LogoIcon} alt="Snoozify icon logo" className={classes.logo}></img>
        </IconButton>

        <div className={classes.ctaButtonContainer}>{ctaButton}</div>

        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={() => {
            dispatch(toggleDrawer(!drawerOpen));
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

MobileHeader.defaultProps = {
  ctaButton: <div />,
};
export default MobileHeader;
