/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Card, CardContent, CardHeader, CardActions } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

// Own components
import SnoozifyButton from 'components/common/button';

// Data models
export interface OwnProps {
  header: string;
  content: React.ReactElement<any>;
  actionDescription: string;
  action: () => void;
}
// Own components

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      minWidth: '30rem',
    },
    subCard: {
      boxSizing: 'border-box',
      backgroundColor: 'unset',
      minHeight: '24rem',
      height: '100%',
      display: 'flex',
      borderRadius: '0',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    subCardTitle: {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
    },
    subCardBody: {
      // fontSize: '1.5rem',
      // lineHeight: '2rem',
    },
    subCardHeaderRoot: {
      minHeight: '6.4rem',
      backgroundColor: theme.palette.text.primary,
      paddingLeft: '6.2rem',
    },
    subCardAction: {
      padding: '0 1.5rem 0 6.2rem',
    },
    subCardBodyRoot: {
      minHeight: '16.8rem',
      padding: '2.6rem 0 0 6.2rem',
      '&:last-child': {
        paddingBottom: '0',
      },
    },
  }),
);

const AccountDetailCard: React.FC<OwnProps> = (props) => {
  const { content, actionDescription, action, header } = props;

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.subCard}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <CardHeader
                titleTypographyProps={{ variant: 'body1' }}
                classes={{
                  title: classes.subCardTitle,
                  root: classes.subCardHeaderRoot,
                }}
                title={header}
              />
            </Grid>
            <Grid item xs={12}>
              <CardContent
                classes={{
                  root: classes.subCardBodyRoot,
                }}
              >
                {content}
              </CardContent>
            </Grid>
            <Grid item xs={12}>
              <CardActions className={classes.subCardAction}>
                <SnoozifyButton
                  primaryColor={true}
                  buttonWidth="<8Chars"
                  onClick={() => {
                    action();
                  }}
                >
                  {actionDescription}
                </SnoozifyButton>
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AccountDetailCard;
