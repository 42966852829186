/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-07-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

export enum FireStoreError {
  NONE = 'none',
  NOT_FOUND = 'not-found',
  ALREADY_EXISTS = 'already-exists',
  PERMISSION_DENIED = 'permission-denied',
}

export enum FireStorePermissionCreateError {
  NONE = 'none',
  ALREADY_USED = 'already-used',
}
