/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';

const useStyles = makeStyles<Theme>(() => ({
  root: {},
}));

const Invoices: FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container alignItems="flex-start" justify="center">
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export default Invoices;
