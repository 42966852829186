/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { ThunkDispatch } from 'redux-thunk';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

// Action creators
import { updateUser, loadUser } from 'store/user/actions';

// Presentational component
import DetailInvoiceAddress from './DetailInvoiceAddress';

// Data models
import { ApplicationState, ApplicationAction } from 'models/store';
import { StateProps, DispatchProps } from './propTypes';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  profile: state.user.profile,
  unsubscribe: state.user.updateUserProfileUnsubscribe,
  error: state.user.loadUserError,
  progress: state.user.loadUserStatus,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
) => ({
  updateUserProfile: (profile) => {
    dispatch(updateUser(profile));
  },
  loadUserProfile: () => {
    dispatch(loadUser());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailInvoiceAddress);
