/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { createMuiTheme } from '@material-ui/core/styles';

// Own config
import * as colors from './colors';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      lg: 1920,
      md: 1140,
      sm: 703,
      xl: 1920,
      xs: 0,
    },
  },
  palette: {
    background: {
      default: colors.GREY3,
      paper: colors.GREY20,
    },
    contrastThreshold: 3,
    error: {
      main: colors.DARKBLUE,
    },
    primary: {
      contrastText: colors.WHITE,
      dark: colors.PURPLEDARK,
      main: colors.PURPLE,
    },
    secondary: {
      contrastText: colors.WHITE,
      dark: colors.DARKBLUE,
      main: colors.LIGHTBLUE,
    },
    success: {
      main: colors.PURPLE,
      light: colors.GREEN,
    },
    text: {
      primary: colors.GREY80,
    },
    warning: {
      main: colors.ORANGE,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiIconButton: {
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiPaper: {
      elevation: 6,
    },
    MuiLink: {
      underline: 'none',
    },
  },
  typography: {
    body1: {
      fontSize: '1.6rem',
      fontWeight: 'lighter',
      lineHeight: '1.6rem',
    },
    body2: {
      fontSize: '1.4rem',
      fontWeight: 'lighter',
      lineHeight: '1.4rem',
    },
    h2: {
      fontSize: '3.6rem',
      fontWeight: 'bold',
      lineHeight: '3.6rem',
    },
    h3: {
      fontSize: '2.6rem',
      fontWeight: 'bold',
      lineHeight: '2.6rem',
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      lineHeight: '1.8rem',
    },
    h5: {
      color: colors.GREY80,
      fontSize: '2.7rem',
      lineHeight: '2.7rem',
    },
    fontFamily: ['aktiv-grotesk', 'Roboto', 'Arial', 'sans-serif'].join(','),
    htmlFontSize: 10,
  },
  spacing: 4,
  overrides: {
    MuiLink: {
      root: {
        fontWeight: 'bold',
        textDecoration: 'none',
        cursor: 'pointer',
      },
      underlineHover: {
        textDecoration: 'none',
      },
    },
    MuiCheckbox: {
      root: {
        paddingLeft: '2rem',
        '& .MuiSvgIcon-root': {
          fontSize: '2.9rem',
        },
      },
    },
    MuiTextField: {
      root: {
        paddingBottom: '5.6rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 12,
      },
      label: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
      },
      containedSizeSmall: {
        fontSize: '1.4rem',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
        },
        borderRadius: 12,
      },
    },
  },
});

export default theme;

export type SnoozifyTheme = typeof theme;
