/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  CircularProgress,
  Grid,
  Link,
  Snackbar,
  SnackbarContent,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import * as React from 'react';
import { ChangeEvent, FC, Fragment, ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';

// Own components
import PasswordField from 'components/common/password-field';
import SnoozifyTextField from 'components/common/text-field';

// Utils
import { regExpEmail } from 'utils/inputData';
import { hasGoodLength, hasLower, hasNumber, hasSymbol, hasUpper } from 'utils/password';

// Config
import { LOGIN_PATH, THANKS_PATH } from 'config/routes';

// Data models
import { Props } from './propTypes';
import { SignupData, SignupError } from 'models/signup';
import { RequestStatus } from 'models/common';
import SnoozifyButton from 'components/common/button';
import SnoozifyCheckbox from 'components/common/checkBox';

// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  link: {
    cursor: 'pointer',
  },
  snackbarContent: {
    backgroundColor: (props) =>
      props.progress === RequestStatus.ERROR
        ? theme.palette.error.main
        : props.progress === RequestStatus.SUCCESS
        ? green[600]
        : '',
  },
  formControl: {
    marginLeft: 0,
    '& .MuiCheckbox-root': {
      paddingLeft: '0.9rem',
    },
  },
  root: {
    height: '100%',
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  snackbarIcon: {
    marginRight: '1rem',
  },
  snackbarMessage: {
    backgroundColor: 'inherit',
    display: 'flex',
  },
  title: {
    paddingTop: theme.spacing(21),
  },
  subTitle: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    fontWeight: 'bold',
  },
  buttonSpacing: {
    paddingTop: theme.spacing(13),
  },
  inputSpacing: {
    paddingBottom: theme.spacing(9),
  },
  checkboxSpacing: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(9),
  },
  linkText: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  checkboxFormHelperText: {
    paddingLeft: '1.5rem',
  },
  linkTypo: {
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
}));

const SignupDialog: FC<Props> = (props) => {
  const { changeData, error, data, history, progress, resetData, submit } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const { t } = useTranslation();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (progress === RequestStatus.SUCCESS) {
      history.push(THANKS_PATH);
    }
  }, [history, progress]);

  // Clean up on leaving sign up page
  useEffect(
    () => () => {
      resetData();
    },
    [resetData],
  );

  /**
   * Handles the input change by user input and stores the new value inside the data object by
   * dispatching an action creator.
   *
   * @param value - New value from input element
   * @param field - Target key for data set to store new value
   */
  const onInput = <K extends keyof SignupData>(value: SignupData[K], field: K) => {
    let update: SignupData = {
      ...data,
    };
    update[field] = value;
    verifyData(field, update);
    changeData(update);
  };

  /**
   * Verifies a new value from input field if it matches the specified criteria for valid values,
   * e.g. password criteria or a valid email address. Will mutate the original data object by
   * setting corresponding data values.
   *
   * @param key - field to verify the value for
   * @param update - New value for the target key
   */
  const verifyData = <K extends keyof SignupData>(key: K, update: SignupData) => {
    switch (key) {
      case 'email': {
        update.emailIsValid = regExpEmail.test(update.email);
        break;
      }
      case 'password': {
        update.passwordHasLowerCase = hasLower.test(update.password);
        update.passwordHasNumber = hasNumber.test(update.password);
        update.passwordHasSymbol = hasSymbol.test(update.password);
        update.passwordHasUpperCase = hasUpper.test(update.password);
        update.passwordLengthIsValid = hasGoodLength.test(update.password);
        break;
      }
    }
  };

  const handleSignUp = () => {
    submit(data);
  };

  const getErrorMessage = (): string => {
    if (data && !data.emailIsValid) {
      return t('common.emailInvalid');
    }
    switch (error) {
      case SignupError.EMAIL_ALREADY_IN_USE: {
        return t('signUp.emailAlreadyInUse');
      }
      case SignupError.INVALID_EMAIL: {
        return t('common.emailInvalid');
      }
      case SignupError.OPERATION_NOT_ALLOWED: {
        return t('signUp.notAllowed');
      }
      case SignupError.WEAK_PASSWORD: {
        return t('signUp.weakPassword');
      }
      default: {
        return '';
      }
    }
  };

  /**
   * Depending on the request progress status value the content of the snackbar will be returned,
   * e.g. a loading indicator when waiting for a response on signup.
   */
  const getSnackbarContent = (): ReactElement | string => {
    let content: ReactElement | string;
    switch (progress) {
      case RequestStatus.LOADING: {
        content = (
          <span className={classes.snackbarMessage}>
            <CircularProgress className={classes.snackbarIcon} color="primary" size={30} />
            <Typography>{t('common.oneMomentPlease')}</Typography>{' '}
          </span>
        );
        break;
      }
      case RequestStatus.SUCCESS: {
        content = (
          <span className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            <Typography align="center">{t('signUp.succeeded')}</Typography>
          </span>
        );
        break;
      }
      default: {
        content = '';
        break;
      }
    }
    return <SnackbarContent className={classes.snackbarContent} message={content} />;
  };

  const LinkComponent = (props: { link: string; children?: string }) => {
    return (
      <Link
        className={classes.linkText}
        variant="body1"
        color="primary"
        href={props.link}
        target="_blank"
      >
        {props.children}
      </Link>
    );
  };

  return (
    <Fragment>
      <Helmet title={`${t('signUp.title')} | Snoozify`}></Helmet>
      <Grid className={classes.root} container alignItems="flex-start" justify="center">
        <Grid item xs={12}>
          <Grid container alignContent="flex-start" justify="center">
            <Grid item xs={12}>
              <Typography align="center" className={classes.title} variant="h2">
                {t('signUp.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" className={classes.subTitle} variant="body1">
                {t('signUp.subTitle')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <form>
          <Grid item xs={12}>
            <Grid item xs={12} container justify="center">
              <Grid
                container
                alignItems="flex-start"
                justify="center"
                style={{ width: 'min-content' }}
              >
                <Grid item xs={12} className={classes.inputSpacing}>
                  <SnoozifyTextField
                    snoozifyVariant={mediumSize ? 'large' : 'medium'}
                    autoComplete="username"
                    autoFocus
                    error={
                      error === SignupError.EMAIL_ALREADY_IN_USE ||
                      error === SignupError.INVALID_EMAIL ||
                      error === SignupError.OPERATION_NOT_ALLOWED ||
                      !data.emailIsValid
                    }
                    helperText={getErrorMessage()}
                    label={t('labels.email')}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                      onInput(event.target.value, 'email')
                    }
                    size="small"
                    type="email"
                    value={data.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} className={classes.inputSpacing}>
                  <PasswordField
                    snoozifyVariant={mediumSize ? 'large' : 'medium'}
                    autoComplete="new-password"
                    checkPassword
                    error={error === SignupError.WEAK_PASSWORD}
                    fullWidth
                    popperText={t('signUp.passwordInvalid')}
                    label={t('labels.password')}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                      onInput(event.target.value, 'password')
                    }
                    size="small"
                    value={data.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordField
                    snoozifyVariant={mediumSize ? 'large' : 'medium'}
                    autoComplete="new-password"
                    error={
                      error === SignupError.WEAK_PASSWORD ||
                      (data.passwordRepeat !== '' && data.passwordRepeat !== data.password)
                    }
                    fullWidth
                    helperText={
                      data.passwordRepeat === '' || data.passwordRepeat === data.password
                        ? ''
                        : t('signUp.passwordRepeatNoMatch')
                    }
                    label={t('labels.repeatPassword')}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                      onInput(event.target.value, 'passwordRepeat')
                    }
                    size="small"
                    value={data.passwordRepeat}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} className={classes.checkboxSpacing}>
                  <Grid
                    item
                    container
                    xs={12}
                    justify="flex-start"
                    style={{ paddingBottom: '2rem' }}
                  >
                    <SnoozifyCheckbox
                      required
                      error={error === SignupError.TERMS_NOT_ACCEPTED}
                      checked={data.termsAreAccepted}
                      onChange={(event: ChangeEvent<{}>, checked: boolean) => {
                        onInput(checked, 'termsAreAccepted');
                      }}
                      label={
                        <Typography className={classes.linkTypo}>
                          <Trans i18nKey="signUp.acceptTerms">
                            {[<LinkComponent key="terms" link="https://Snoozify.com/agb" />]}
                          </Trans>
                        </Typography>
                      }
                      helperText={
                        error === SignupError.TERMS_NOT_ACCEPTED
                          ? t('signUp.pleaseAcceptTerms')
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item container xs={12} justify="flex-start">
                    <SnoozifyCheckbox
                      required
                      error={error === SignupError.PRIVACY_NOT_ACCEPTED}
                      checked={data.privacyIsAccepted}
                      onChange={(event: ChangeEvent<{}>, checked: boolean) => {
                        onInput(checked, 'privacyIsAccepted');
                      }}
                      label={
                        <Typography className={classes.linkTypo}>
                          <Trans i18nKey="signUp.acceptPrivacy">
                            {[
                              <LinkComponent
                                key="privacy"
                                link="https://Snoozify.com/datenschutzerklaerung"
                              />,
                            ]}
                          </Trans>
                        </Typography>
                      }
                      helperText={
                        error === SignupError.PRIVACY_NOT_ACCEPTED
                          ? t('signUp.pleaseAcceptPrivacy')
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center" className={classes.buttonSpacing}>
            <Grid item container justify="center" xs={12}>
              <SnoozifyButton
                buttonWidth=">12Chars"
                color="primary"
                fullWidth
                onClick={handleSignUp}
                variant="contained"
              >
                {t('signUp.callToAction')}
              </SnoozifyButton>
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="center" justify="center">
                <Grid item container justify="center" xs={12} style={{ paddingTop: '3.6rem' }}>
                  <Typography variant="body1" align="center">
                    {t('signUp.alreadyHaveAnAccount')}
                  </Typography>
                </Grid>
                <Grid item container justify="center" xs={12} style={{ paddingTop: '1.6rem' }}>
                  <Link
                    variant="body1"
                    className={classes.loginButton}
                    onClick={(): void => history.push(LOGIN_PATH)}
                  >
                    {t('labels.toLogin')}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={[RequestStatus.LOADING, RequestStatus.SUCCESS].includes(progress)}>
        {getSnackbarContent()}
      </Snackbar>
    </Fragment>
  );
};

export default SignupDialog;
