/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models

export interface LoginData {
  email: string;
  password: string;
  remember: boolean;
}

export enum LoginError {
  NONE = 'none',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
  WRONG_PASSWORD = 'auth/wrong-password',
}

export interface RequestPasswordResetData {
  email: string;
}

export enum RequestPasswordResetError {
  NONE = 'none',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_NOT_FOUND = 'auth/user-not-found',
}

export enum ResetPasswordError {
  NONE = 'none',
  AUTH_CODE_EXPIRED = 'auth/expired-action-code',
  AUTH_CODE_INVALID = 'auth/invalid-action-code',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
  WEAK_PASSWORD = 'auth/weak-password',
}
