/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { ReactElement } from 'react';

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface RequestError {
  errorMessage: string;
}

export enum Language {
  EN = 'en',
  DE = 'de',
}

export interface NavBaseEntry {
  icon?: string;
  id: string;
  matIcon?: ReactElement;
  label: string;
}

export interface TabEntry {
  id: string;
  label: string;
  content?: React.ReactElement;
  path: string;
}

export interface NavPlainEntry extends NavBaseEntry {
  subEntries?: NavPlainEntry[];
  target: string;
  type: 'entry';
}

export interface NavMenuEntry extends NavBaseEntry {
  entries: Array<NavPlainEntry | NavActionEntry>;
  type: 'menu';
}

export interface NavActionEntry extends NavBaseEntry {
  action: (args?: any[]) => void;
  type: 'action';
}

export type NavEntry = NavPlainEntry | NavMenuEntry | NavActionEntry;

export interface FooterEntry {
  id: string;
  label: string;
  target: string;
}
