/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-02-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

// Action creators
import { setRedirectUrl } from 'store/auth/authSlice';

// Presentational compoent
import PrivateRoute from './PrivateRoute';

// Data models
import { DispatchProps, OwnProps, StateProps } from './propTypes';
import { RootState, AppDispatch } from 'store';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: AppDispatch,
) => ({
  setRedirectUrl: (url) => {
    dispatch(setRedirectUrl({ url: url }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
