/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Person as PersonIcon } from '@material-ui/icons';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Config
import { MY_DATA_PATH } from 'config/routes';
import { GREY80 } from 'themes/colors';

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    color: GREY80,
    textTransform: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark,
    },
  },
}));

export const AccountButton: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Button
        className={classes.root}
        onClick={() => history.push(MY_DATA_PATH)}
        startIcon={<PersonIcon />}
      >
        {t('mainNav.account')}
      </Button>
    </Fragment>
  );
};

export default AccountButton;
