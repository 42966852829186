/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-04
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

// Data models
import { RequestStatus } from 'models/common';
import { SignupData, SignupError, ConfirmError } from 'models/signup';
import {
  ConfirmStatusUpdateAction,
  ResetSignupDataAction,
  SetSignupDataAction,
  SignupActionType,
  SignupStatusUpdateAction,
} from 'models/signup/actions';
import { ApplicationAction } from 'models/store';
import { Functions } from 'models/firebase/functions';
import { PermissionInvitationResponse } from 'models/firebase/permission';

// Environment
import { BACKEND_URL } from 'config/env';
import { updateLoadPermissionStatus, setInvitationMail } from 'store/permission/actions';
import { FireStoreError } from 'models/firebase';

export const setConfirmStatus = (
  status: RequestStatus,
  error: ConfirmError = ConfirmError.NONE,
): ConfirmStatusUpdateAction => ({
  error,
  status,
  type: SignupActionType.CONFIRM_STATUS_UPDATE,
});

export const resetSignupData = (): ResetSignupDataAction => ({
  type: SignupActionType.RESET_DATA,
});

export const setSignupData = (data: SignupData): SetSignupDataAction => ({
  payload: data,
  type: SignupActionType.SET_DATA,
});

export const setSignupStatus = (
  status: RequestStatus,
  error: SignupError = SignupError.NONE,
): SignupStatusUpdateAction => ({
  payload: {
    status,
    error,
  },
  type: SignupActionType.SIGNUP_STATUS_UPDATE,
});

export const signup = (data: SignupData): ThunkAction<Promise<void>, {}, {}, ApplicationAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, ApplicationAction>): Promise<void> => {
    dispatch(setSignupStatus(RequestStatus.LOADING));
    try {
      if (!data.termsAreAccepted) {
        dispatch(setSignupStatus(RequestStatus.ERROR, SignupError.TERMS_NOT_ACCEPTED));
      }

      if (!data.privacyIsAccepted) {
        dispatch(setSignupStatus(RequestStatus.ERROR, SignupError.PRIVACY_NOT_ACCEPTED));
      }

      if (data.termsAreAccepted && data.privacyIsAccepted) {
        // Create new user at auth database. The cofirmation email will be sent from backend
        await firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
        dispatch(setSignupStatus(RequestStatus.SUCCESS));
      }
    } catch (error) {
      dispatch(setSignupStatus(RequestStatus.ERROR, error.code as SignupError));
    }
  };
};

export const confirmEmail = (
  code: string,
): ThunkAction<Promise<void>, {}, {}, ApplicationAction> => async (
  dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
) => {
  dispatch(setConfirmStatus(RequestStatus.LOADING));
  try {
    await firebase.auth().applyActionCode(code);
    dispatch(setConfirmStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setConfirmStatus(RequestStatus.ERROR, error.code));
  }
};

export const getPermissionInvitation = (permissionId: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, ApplicationAction>): Promise<void> => {
    dispatch(updateLoadPermissionStatus(RequestStatus.LOADING));
    try {
      await fetch(BACKEND_URL + `/snoozifyApp/${Functions.PERMISSION}/${permissionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then(async (response) => {
        if (response.ok) {
          const body = (await response.json()) as PermissionInvitationResponse;
          dispatch(setInvitationMail(body.payload.invitationMail));
          dispatch(updateLoadPermissionStatus(RequestStatus.SUCCESS));
        } else {
          dispatch(
            updateLoadPermissionStatus(RequestStatus.ERROR, FireStoreError.PERMISSION_DENIED),
          );
          throw Error('Error in permission response');
        }
      });
    } catch (error) {
      dispatch(updateLoadPermissionStatus(RequestStatus.ERROR, error.code));
    }
  };
};
