/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { ThunkDispatch } from 'redux-thunk';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Action creators
import { resetSignupData, setSignupData, signup, setSignupStatus } from 'store/signup/actions';

// Connected Component
import SignupDialog from './SignupDialog';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { RequestStatus } from 'models/common';
import { ApplicationAction, ApplicationState } from 'models/store';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  data: state.signup.data,
  error: state.signup.signupError,
  progress: state.signup.signupStatus,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
) => ({
  changeData: (data) => {
    dispatch(setSignupStatus(RequestStatus.IDLE));
    dispatch(setSignupData(data));
  },
  submit: (data) => {
    dispatch(signup(data));
  },
  resetData: () => {
    dispatch(resetSignupData());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupDialog));
