/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import moment from 'moment';
import { Stripe } from 'stripe';

// Data models
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';

// Action creator
import { getTargetProfile } from 'utils/user/userUtils';

// Utils
import { createAppThunk } from 'utils/appAction';

export type PaymentSubscriptionState = {
  activateSubscriptionStatus: RequestStatus;
  cancelSubscriptionStatus: RequestStatus;
  fetchSubscriptionsStatus: RequestStatus;
  subscriptions: Array<Stripe.Subscription>;
};

export type UpdateSubscriptionsPayload = {
  subscriptions: Array<Stripe.Subscription>;
};

export const initialState: PaymentSubscriptionState = {
  activateSubscriptionStatus: RequestStatus.IDLE,
  cancelSubscriptionStatus: RequestStatus.IDLE,
  fetchSubscriptionsStatus: RequestStatus.IDLE,
  subscriptions: [],
};

const sliceName = '@@payment/subscription';

// export const cancelSubscription = createAppThunk<void, { snoozify: Box }>(
//   sliceName + '/cancelSubscription',
//   async ({ snoozify }, { dispatch, getState, rejectWithValue }) => {
//     try {
//       let customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;

//       if (customerId && customerId !== '' && snoozify.license.subscriptionId) {
//         const response = await fetch(
//           `${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}/subscriptions/${snoozify.license.subscriptionId}`,
//           {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//               Accept: 'application/json',
//             },
//             body: JSON.stringify({ cancel_at_period_end: true }),
//           },
//         );
//         if (!response.ok) {
//           return rejectWithValue({ errorMessage: response.statusText });
//         }
//         const subscription = (await response.json()) as Stripe.Subscription;
//         const subscriptionEnd = moment.unix(subscription.current_period_end);

//         const updatedBox: Box = {
//           ...snoozify,
//           activeUntil: subscriptionEnd,
//           license: {
//             ...snoozify.license,
//             active: false,
//           },
//         };
//         dispatch(saveBox({ box: updatedBox }));
//       } else if (snoozify.license.subscriptionId === '') {
//         const updatedBox: Box = {
//           ...snoozify,
//           activeUntil: moment(),
//           license: {
//             ...snoozify.license,
//             active: false,
//           },
//         };
//         dispatch(saveBox({ box: updatedBox }));
//       }
//     } catch (err) {
//       return rejectWithValue({ errorMessage: err });
//     }
//   },
// );

// export const reactivateSubscription = createAppThunk<void, { snoozify: Box }>(
//   sliceName + '/reactivateSubscription',
//   async ({ snoozify }, { dispatch, getState, rejectWithValue }) => {
//     try {
//       let customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;

//       if (customerId && customerId !== '' && snoozify.license.subscriptionId) {
//         const response = await fetch(
//           `${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}/subscriptions/${snoozify.license.subscriptionId}`,
//           {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//               Accept: 'application/json',
//             },
//             body: JSON.stringify({ cancel_at_period_end: false }),
//           },
//         );
//         if (!response.ok) {
//           return rejectWithValue({ errorMessage: response.statusText });
//         }

//         const updatedBox: Box = {
//           ...snoozify,
//           activeUntil: null,
//           license: {
//             ...snoozify.license,
//             active: false,
//           },
//         };
//         dispatch(saveBox({ box: updatedBox }));
//       }
//     } catch (err) {
//       return rejectWithValue({ errorMessage: err });
//     }
//   },
// );

export const getSubscriptions = createAppThunk(
  sliceName + '/getSubscriptions',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      let customerId = getTargetProfile(getState().user)?.paymentDetails.customerId;

      if (customerId) {
        const subscriptions = await (
          await fetch(`${BACKEND_URL}/snoozifyApp/payment/customers/${customerId}/subscriptions`)
        ).json();
        dispatch(updateSubscriptions({ subscriptions }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

const paymentSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updateSubscriptions(state, action: PayloadAction<UpdateSubscriptionsPayload>) {
      state.subscriptions = action.payload.subscriptions;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(cancelSubscription.pending, (state, _) => {
    //   state.cancelSubscriptionStatus = RequestStatus.LOADING;
    // });
    // builder.addCase(cancelSubscription.fulfilled, (state, _) => {
    //   state.cancelSubscriptionStatus = RequestStatus.IDLE;
    // });
    // builder.addCase(cancelSubscription.rejected, (state, action) => {
    //   state.cancelSubscriptionStatus = RequestStatus.ERROR;
    // });

    // builder.addCase(reactivateSubscription.pending, (state, _) => {
    //   state.activateSubscriptionStatus = RequestStatus.LOADING;
    // });
    // builder.addCase(reactivateSubscription.fulfilled, (state, _) => {
    //   state.activateSubscriptionStatus = RequestStatus.IDLE;
    // });
    // builder.addCase(reactivateSubscription.rejected, (state, action) => {
    //   state.activateSubscriptionStatus = RequestStatus.ERROR;
    // });

    builder.addCase(getSubscriptions.pending, (state, _) => {
      state.fetchSubscriptionsStatus = RequestStatus.LOADING;
    });
    builder.addCase(getSubscriptions.fulfilled, (state, _) => {
      state.fetchSubscriptionsStatus = RequestStatus.IDLE;
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.fetchSubscriptionsStatus = RequestStatus.ERROR;
    });
  },
});

export const { updateSubscriptions } = paymentSlice.actions;

export default paymentSlice.reducer;
