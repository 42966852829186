/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Views
import PrivateView from 'views/private';
import PublicView from 'views/public';

// Action creators
import { signOut, updateAuthData } from 'store/auth/authSlice';
import { updateUserState } from 'store/user/actions';

// Data models
import { AuthError } from 'models/auth';
import { ApplicationState } from 'models/store';
import { SnoozifyUser } from 'models/user';

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    marginTop: theme.spacing(5),
  },
  loader: {
    marginBottom: '2rem',
  },
  root: {
    height: '100%',
    minHeight: '100vh',
  },
}));

const Root: FC = () => {
  const dispatch = useDispatch();
  const profile = useSelector<ApplicationState, SnoozifyUser>((state) => state.user.profile);
  const classes = useStyles();
  const { t } = useTranslation();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const parsedData = window.location.hostname.split('.');
    console.log(parsedData);
    if (parsedData.length >= 2) {
      const subDomain = parsedData[0];
      console.log(subDomain);
    }
  }, []);

  // DidMount hook
  useEffect(() => {
    /* Register auth state change handler. Will also react on loaded session from local storage if
     * existing. There will also be a redirect to login if the user is no longer signed in due to a
     * no longer valid refreshtoken or a changed password.
     */
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user: firebase.User | null) => {
        if (!user) {
          // User no longer authenticated but no error -> just sign out
          dispatch(updateAuthData({ error: AuthError.NONE, isAuthenticated: false }));
          signOut();
        } else {
          // Write new data to store
          dispatch(updateAuthData({ error: AuthError.NONE, isAuthenticated: true, user: user }));
          let profileUpdate: SnoozifyUser = {
            ...profile,
            email: user.email || '',
            uid: user.uid,
          };
          dispatch(updateUserState(profileUpdate));
        }
        setInitialized(true);
      },
      (error) => {
        // There were errors during authentication
        dispatch(updateAuthData({ error: error.code as AuthError, isAuthenticated: false }));
        setInitialized(true);
      },
    );
    // Cleanup
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContent = () => {
    if (initialized) {
      return (
        <Switch>
          <Route path="/(login|signup|welcome|)" component={PublicView} />
          <Route component={PrivateView} />
        </Switch>
      );
    } else {
      return (
        <Grid
          alignItems="center"
          container
          direction="column"
          item
          xs={12}
          id="loading-root"
          justify="center"
        >
          <CircularProgress className={classes.loader} size={300} thickness={3} />
          <Typography align="center" className={classes.info} variant="h4">
            {t('common.appLoading')}
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Grid className={classes.root} container item xs={12} id="root-container">
      {getContent()}
    </Grid>
  );
};

export default Root;
