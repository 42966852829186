/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Reducer } from 'redux';

// Data models
import { SignupState } from 'models/signup/state';
import { SignupActionType, SignupAction } from 'models/signup/actions';
import { RequestStatus } from 'models/common';
import { SignupError, ConfirmError } from 'models/signup';

export const initialState: SignupState = {
  confirmError: ConfirmError.NONE,
  confirmStatus: RequestStatus.IDLE,
  data: {
    email: '',
    emailIsValid: true,
    password: '',
    passwordHasLowerCase: true,
    passwordHasNumber: true,
    passwordHasSymbol: true,
    passwordHasUpperCase: true,
    passwordLengthIsValid: true,
    passwordRepeat: '',
    privacyIsAccepted: false,
    termsAreAccepted: false,
  },
  signupError: SignupError.NONE,
  signupStatus: RequestStatus.IDLE,
};

export const signupReducer: Reducer<SignupState, SignupAction> = (state = initialState, action) => {
  switch (action.type) {
    case SignupActionType.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case SignupActionType.SIGNUP_STATUS_UPDATE: {
      return {
        ...state,
        signupStatus: action.payload.status,
        signupError: action.payload.error,
      };
    }
    case SignupActionType.CONFIRM_STATUS_UPDATE: {
      return {
        ...state,
        confirmError: action.error,
        confirmStatus: action.status,
      };
    }
    case SignupActionType.RESET_DATA: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default signupReducer;
