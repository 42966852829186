/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-02-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

// Presentational component
import EmailConfirmation from './EmailConfirmation';

// Action creators
import { confirmEmail } from 'store/signup/actions';

// Data models
import { DispatchProps, StateProps } from './propTypes';
import { ApplicationState, ApplicationAction } from 'models/store';
import { withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';

const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => ({
  confirmError: state.signup.confirmError,
  confirmStatus: state.signup.confirmStatus,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, ApplicationAction> = (
  dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
) => ({
  confirm: (code) => {
    dispatch(confirmEmail(code));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation));
