/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models

export interface SignupData extends PasswordData {
  email: string;
  emailIsValid: boolean;
  privacyIsAccepted: boolean;
  termsAreAccepted: boolean;
}

export interface PasswordData {
  password: string;
  passwordRepeat: string;
  passwordLengthIsValid: boolean;
  passwordHasNumber: boolean;
  passwordHasLowerCase: boolean;
  passwordHasUpperCase: boolean;
  passwordHasSymbol: boolean;
}

export enum SignupError {
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  OPERATION_NOT_ALLOWED = 'auth/operation-not-allowed',
  WEAK_PASSWORD = 'auth/weak-password',
  TERMS_NOT_ACCEPTED = 'auth/terms-not-accepted',
  PRIVACY_NOT_ACCEPTED = 'auth/privacy-not-accepted',
  NONE = 'none',
}

export enum ConfirmError {
  NONE = 'none',
  EXPIRED = 'auth/expired-action-code',
  INVALID = 'auth/invalid-action-code',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
}
