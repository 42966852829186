/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Container, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Components
import LoginDialog from 'components/login/login-dialog';

// Constants
import { LOGIN_PATH, FORGOT_PASSWORD_PATH, RESET_PASSWORD_PATH } from 'config/routes';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const LoginView: React.FC = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <Container className={classes.root} maxWidth="sm">
        <Switch>
          <Route exact path={LOGIN_PATH} component={LoginDialog} />
          <Route path={FORGOT_PASSWORD_PATH} />
          <Route path={RESET_PASSWORD_PATH} />
          <Redirect to={LOGIN_PATH} />
        </Switch>
      </Container>
    </Fragment>
  );
};

export default LoginView;
