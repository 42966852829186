/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-06-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { SnoozifyUser } from 'models/user';
import { FireStoreError } from 'models/firebase';
import { RequestStatus } from 'models/common';

export enum UserActionType {
  USER_PROFILE = '@@user/USER_PROFILE',
  SET_MANAGED_USER_PROFILE = '@@user/SET_MANAGED_USER_PROFILE',
  LOAD_USER_STATUS = '@@user/LOAD_USER_STATUS',
  UPDATE_USER_PROFILE_UNSUBSCRIBE = '@@user/UPDATE_USER_PROFILE_UNSUBSCRIBE',
}

export interface UserProfileAction {
  profile: SnoozifyUser;
  type: UserActionType.USER_PROFILE;
}

export interface SetManagedUserProfileAction {
  profile: SnoozifyUser | null;
  type: UserActionType.SET_MANAGED_USER_PROFILE;
}

export interface LoadUserStatusAction {
  error: FireStoreError;
  status: RequestStatus;
  type: UserActionType.LOAD_USER_STATUS;
}

export interface UpdateUserProfileUnsubscribeAction {
  unsubscribe: () => void;
  type: UserActionType.UPDATE_USER_PROFILE_UNSUBSCRIBE;
}

export type UserAction =
  | UserProfileAction
  | LoadUserStatusAction
  | UpdateUserProfileUnsubscribeAction
  | SetManagedUserProfileAction;
