/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-14
 * @author Tim Lange <tl@systl.de>
 */

import { createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { RequestError } from 'models/common';

type ThunkApiConfig = {
  dispatch: AppDispatch;
  state: RootState;
  rejectValue: RequestError;
};

export const createAppThunk = <Returned = void, ThunkArg = void>(
  type: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
) => createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(type, payloadCreator);
