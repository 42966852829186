/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-14
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { SnoozifyUser } from 'models/user';
import { UserState } from 'models/user/state';

/**
 * Depending on current logged in user type the own profile ( accountType === 'primary' ) or the
 * managed profile ( targetProfile === 'sub' ) will be returned if it exists.
 *
 * @param state - Complete user state object
 */
export const getTargetProfile = (state: UserState): SnoozifyUser | null => {
  return state.profile.accountType === 'primary' ? state.profile : state.managedProfile;
};
