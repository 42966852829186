/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';

// Config
import { LOGIN_PATH } from 'config/routes';

// Data models
import { Props } from './propTypes';

export const PrivateRoute: React.FC<Props> = (props) => {
  const { component: Component, isAuthenticated, setRedirectUrl } = props;
  const history = useHistory();
  const location = useLocation();

  if (!isAuthenticated) {
    setRedirectUrl(location.pathname + location.search);
    history.push(LOGIN_PATH);
  }

  return (
    <Route
      render={(otherProps) => {
        return <Component {...otherProps} />;
      }}
    />
  );
};

export default PrivateRoute;
