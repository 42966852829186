/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

export interface AuthPayload {
  jwt: string;
}

export enum Functions {
  PERMISSION = 'permission',
  DEPLOY = 'deploy',
}
