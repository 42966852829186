/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { SignupData, SignupError, ConfirmError } from './index';
import { RequestStatus } from '../common';

export enum SignupActionType {
  SET_DATA = '@@signup/SET_DATA',
  RESET_DATA = '@@signup/RESET_DATA',
  SIGNUP_STATUS_UPDATE = '@@signup/SIGNUP_STATUS_UPDATE',
  CONFIRM_STATUS_UPDATE = '@@signup/CONFIRM_STATUS_UPDATE',
}

export interface SetSignupDataAction {
  payload: SignupData;
  type: SignupActionType.SET_DATA;
}

export interface SignupStatusUpdateAction {
  payload: {
    status: RequestStatus;
    error: SignupError;
  };
  type: SignupActionType.SIGNUP_STATUS_UPDATE;
}

export interface ResetSignupDataAction {
  type: SignupActionType.RESET_DATA;
}

export interface ConfirmStatusUpdateAction {
  error: ConfirmError;
  status: RequestStatus;
  type: SignupActionType.CONFIRM_STATUS_UPDATE;
}

export type SignupAction =
  | SetSignupDataAction
  | SignupStatusUpdateAction
  | ResetSignupDataAction
  | ConfirmStatusUpdateAction;
