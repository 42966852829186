/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid, Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Models
import { OwnProps } from './propTypes';

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    title: {
      padding: '8.4rem 0rem',
    },
    tabsRoot: {
      color: '#333333',
      minHeight: 'unset',
    },
    tabContainer: {
      '& .Mui-selected': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        color: '#FF3A82',
      },
      '& .MuiTab-textColorInherit': {
        opacity: '1',
      },
    },
    tabRoot: {
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
      borderBottom: `2px solid transparent`,
      lineHeight: '1.6rem',
      textTransform: 'capitalize',
      //height: '3.2rem',
      fontWeight: 'bolder',
      fontSize: '1.6rem',
      minHeight: 'unset',
      maxHeight: 'unset',
      padding: '0 0 1rem 0',
      minWidth: 'unset',
      maxWidth: 'unset',
      margin: '0 1rem',
      [theme.breakpoints.up('md')]: {
        margin: '0 6.25rem',
      },
    },
    tabContent: {
      backgroundColor: '#D8D8D8',
      padding: '8.4rem 0 0',
    },
  }),
);

const AccountTabs: React.FC<OwnProps> = (props) => {
  const { component, location, tabs } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title={`${t('mainNav.account')} | Jaybox`}></Helmet>
      <Grid container alignContent="flex-start" justify="center" className={classes.root}>
        <Grid item xs={12}>
          <Typography align="center" className={classes.title} variant="h2">
            {t('mainNav.account')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabs.find((tab) => tab.path === location) ? location : tabs[0].path}
            centered
            TabIndicatorProps={{ style: { backgroundColor: '#FF3A82', height: '0' } }}
            classes={{ flexContainer: classes.tabContainer, root: classes.tabsRoot }}
          >
            {tabs.map((tabEntry) => (
              <Tab
                classes={{ root: classes.tabRoot }}
                key={`${tabEntry.id}-tab`}
                label={tabEntry.label}
                value={tabEntry.path}
                component={Link}
                to={`${tabEntry.path}`}
              />
            ))}
          </Tabs>
          <Grid container className={classes.tabContent}>
            <Grid item xs={12}>
              {component}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AccountTabs;
