/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

// Data models
import { Props } from './propTypes';

// Styles
import './styles.scss';
const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const ProgressLogo: FC<Props> = (props) => {
  const { size } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.root} alignItems="center" justify="center">
      <Grid item xs={12}>
        <CircularProgress size={size} />
      </Grid>
    </Grid>
  );
};

ProgressLogo.defaultProps = {
  size: '40rem',
  speed: 2000,
};

export default ProgressLogo;
