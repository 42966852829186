/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-08
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { FC } from 'react';

// Data models
import { Props } from './propTypes';
import SnoozifyButton from '../button';
import { useTranslation } from 'react-i18next';
// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  dialogRoot: {
    borderRadius: '0',
    padding: '4.8rem',
  },
  titleRoot: {
    justifyContent: 'center',
    padding: '0 0 4.8rem',
  },
  contentRoot: {
    justifyContent: 'center',
    padding: '0',
    overflow: 'initial',
  },
  actionsRoot: {
    justifyContent: 'center',
    padding: '0',
    margin: '0',
    '& > :not(:first-child)': {
      margin: '0',
    },
  },
  closeButton: {
    padding: '0',
    position: 'absolute',
    right: '1.2rem',
    width: '1.6rem',
    height: '1.6rem',
    top: '1.2rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      width: '1.6rem',
      height: '1.6rem',
    },
  },
  icon: {
    color: 'white',
    width: '100%',
    height: '100%',
  },
  iconLabel: {
    width: '100%',
    height: '100%',
    //display: 'contents',
  },
}));

const SnoozifyOverlay: FC<Props> = (props) => {
  const {
    title,
    subTitle,
    open,
    cancelAction,
    confirmAction,
    cancelButtonText,
    confirmButtonDisabled,
    confirmButtonText,
    children,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} container justify="center">
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="sm"
          onEntering={() => {}}
          aria-labelledby="confirmation-dialog-title"
          open={open}
          fullWidth
          classes={{ paper: classes.dialogRoot }}
        >
          <IconButton
            className={classes.closeButton}
            classes={{ label: classes.iconLabel }}
            edge="start"
            arial-label="close-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              if (cancelAction) {
                cancelAction();
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle classes={{ root: classes.titleRoot }} id="confirmation-dialog-title">
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography align="center" variant="h3">
                  {title || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '2.8rem' }}>
                <Typography align="center" variant="body1">
                  {subTitle || ''}
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ paddingBottom: children ? '4.8rem' : '0' }}
            >
              {children || <div />}
            </Grid>
          </DialogContent>
          <DialogActions classes={{ root: classes.actionsRoot }}>
            <span style={{ paddingRight: '2rem' }}>
              <SnoozifyButton
                cta={false}
                primaryColor={false}
                onClick={() => {
                  if (cancelAction) {
                    cancelAction();
                  }
                }}
              >
                {cancelButtonText ? cancelButtonText : t('common.cancel')}
              </SnoozifyButton>
            </span>
            <span>
              <SnoozifyButton
                disabled={confirmButtonDisabled || false}
                onClick={() => {
                  if (confirmAction) {
                    confirmAction();
                  }
                }}
              >
                {confirmButtonText ? confirmButtonText : t('common.save')}
              </SnoozifyButton>
            </span>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

SnoozifyOverlay.defaultProps = {};

export default SnoozifyOverlay;
