/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';
import { History, LocationState } from 'history';

// Reducers
import authReducer from 'store/auth/authSlice';
import commonReducer from 'store/common/reducer';
import loginReducer from 'store/login/reducer';
import paymentReducer from 'store/payment';
import permissionReducer from 'store/permission/reducer';
import signupReducer from 'store/signup/reducer';
import userReducer from 'store/user/reducer';

export const createRootReducer = (history: History<History.PoorMansUnknown>) => {
  // Reducers object
  const rootReducer = combineReducers({
    auth: authReducer,
    common: commonReducer,
    login: loginReducer,
    payment: paymentReducer,
    permission: permissionReducer,
    signup: signupReducer,
    router: (connectRouter(history) as any) as Reducer<RouterState<LocationState>>,
    user: userReducer,
  });

  return rootReducer;
};

export default createRootReducer;
