/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

export const LIGHTBLUE = '#698bea';
export const DARKBLUE = '#3d528e';
export const PURPLE = '#8e54a5';
export const PURPLEDARK = '#573465';

export const GREY80 = '#333333';
export const GREY20 = '#CCCCCC';
export const GREY3 = '#F7F7F7';
export const WHITE = '#FFFFFF';
export const ORANGE = '#FA6400';
export const GREEN = '#6DD400';
