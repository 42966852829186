/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Assets
import SnoozifyLogoFull from 'assets/img/snoozify_logo_light_full.svg';

// Action creators
import { toggleDrawer } from 'store/common/actions';

// Data models
import { Props, StateProps } from './propTypes';
import { ApplicationState } from 'models/store';

// Config
import { DASHBOARD_PATH } from 'config/routes';

const useStyles = makeStyles<Theme>((theme) => ({
  logo: {
    width: '75%',
  },
  logoListItem: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  mainList: {
    paddingTop: 0,
    width: theme.spacing(70),
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
}));

const MainDrawer: FC<Props> = (props) => {
  const { entries } = props;
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector<ApplicationState, StateProps>((state) => ({
    drawerOpen: state.common.drawerOpen,
  }));
  const history = useHistory();
  const classes = useStyles();

  const menuEntries = entries.map((entry) => {
    switch (entry.type) {
      case 'entry': {
        const mainItem = (
          <ListItem
            button
            key={`drawer-entry-${entry.id}`}
            onClick={() => {
              dispatch(toggleDrawer(false));
              history.push(entry.target);
            }}
          >
            <ListItemText primary={entry.label} />
          </ListItem>
        );
        const subList = (
          <List component="div" key={`drawer-${entry.id}-subitems`}>
            {entry.subEntries &&
              entry.subEntries.map((subEntry) => (
                <ListItem
                  button
                  className={classes.nested}
                  key={`drawer-${entry.id}-subitem-${subEntry.id}`}
                  onClick={() => {
                    dispatch(toggleDrawer(false));
                    history.push(subEntry.target);
                  }}
                >
                  <ListItemText primary={subEntry.label} />
                </ListItem>
              ))}
          </List>
        );
        return entry.subEntries ? [mainItem, subList] : mainItem;
      }
      case 'action': {
        return (
          <ListItem
            button
            key={`drawer-entry-${entry.id}`}
            onClick={() => {
              dispatch(toggleDrawer(false));
              entry.action();
            }}
          >
            <ListItemText primary={entry.label} />
          </ListItem>
        );
      }
      default: {
        return '';
      }
    }
  });

  return (
    <Drawer
      onClose={() => {
        dispatch(toggleDrawer(false));
      }}
      open={drawerOpen}
    >
      <List className={classes.mainList}>
        <ListItem
          button
          className={classes.logoListItem}
          disableGutters
          onClick={() => {
            history.push(DASHBOARD_PATH);
            toggleDrawer(false);
          }}
        >
          <img alt="Snoozify logo" className={classes.logo} src={SnoozifyLogoFull} />
        </ListItem>
        {menuEntries}
      </List>
    </Drawer>
  );
};

export default MainDrawer;
