/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  FormControl,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';

// Assets
import { ReactComponent as CheckboxIcon } from 'assets/img/snoozify_checkbox.svg';
import { ReactComponent as CheckboxActiveIcon } from 'assets/img/snoozify_checkbox_active.svg';

// Data models
import { Props } from './propTypes';

// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    padding: '0',
  },

  label: {
    fontSize: '1.2rem',
    color: `${theme.palette.text.primary} !important`,
    padding: '0 0 0.8rem 2rem',
    position: 'absolute',
    transform: 'translate(0, -2rem)',
  },
  helperText: {
    color: theme.palette.text.primary,
    padding: (props) => (props.helperText ? '0.8rem 0 0 2rem' : 0),
    maxWidth: '38rem',
    lineHeight: '1.2rem',
    fontSize: '1.2rem',
    margin: (props) => (props.helperText ? '0 0 -0.4rem 0' : 0),
  },
  helperError: {
    color: theme.palette.secondary.dark,
  },
  checkboxFormHelperText: {
    paddingLeft: '1.5rem',
  },
  linkTypo: {
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
  checkboxRoot: {
    padding: '0 1.85rem 0 0',
    '&:hover': {
      '& #Rectangle': {
        stroke: theme.palette.primary.dark,
      },
    },
  },
  icon: {
    width: '2.9rem',
    height: '2.9rem',
  },
}));

const SnoozifyCheckbox: FC<Props> = (props) => {
  const { helperText, label, checked, onChange } = props;
  const classes = useStyles(props);

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} container justify="center" style={{ boxSizing: 'content-box' }}>
        <FormControl required error={true} component="fieldset">
          <FormGroup>
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  checked={checked}
                  onChange={onChange}
                  icon={<CheckboxIcon className={classes.icon} />}
                  checkedIcon={<CheckboxActiveIcon className={classes.icon} />}
                />
              }
              label={label}
            />
          </FormGroup>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

SnoozifyCheckbox.defaultProps = {};

export default SnoozifyCheckbox;
