/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

// Own Components
import EmailConfirmation from 'components/signup/email-confirmation/EmailConfirmationContainer';
import SignupDialog from 'components/signup/signup-dialog';
import Thanks from 'components/signup/thanks/Thanks';

// Config
import { CONFIRM_EMAIL_PATH, SIGN_UP_PATH, THANKS_PATH } from 'config/routes';

// Styles
const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const Signup: FC = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <Container className={classes.root} maxWidth="sm">
        <Switch>
          <Route exact path={SIGN_UP_PATH} component={SignupDialog} />
          <Route path={THANKS_PATH} component={Thanks} />
          <Route path={CONFIRM_EMAIL_PATH} component={EmailConfirmation} />
          <Redirect to={SIGN_UP_PATH} />
        </Switch>
      </Container>
    </Fragment>
  );
};

export default Signup;
