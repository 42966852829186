/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-22
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Container, Grid, Hidden } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Dashboard as DashboardIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect, useHistory } from 'react-router-dom';

// View components
import AccountView from 'views/account/AccountView';
import Footer from 'components/footer';
import DashboardView from 'views/dashboard/DashboardView';
import DesktopHeader from 'components/header/desktop-header';
import MainDrawer from 'components/nav/main-drawer';
import MobileHeader from 'components/header/mobile-header';
import AccountButton from 'components/header/account-button';
import SnoozifyButton from 'components/common/button';

// Private Route
import PrivateRoute from 'hoc/PrivateRoute/PrivateRouteContainer';

// Action creators
import { signOut } from 'store/auth/authSlice';

// Data models
import { NavEntry } from 'models/common';

// Config
import { ACCOUNT_PATH, DASHBOARD_PATH, INVOICES_PATH, LOGIN_PATH, PLAN_PATH } from 'config/routes';

// Props
interface StateProps {}

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  contentWrapper: {
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${theme.spacing(12)}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.spacing(17.5)}px)`,
    },
    overflowY: 'auto',
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      minHeight: `calc(100% - ${theme.spacing(12)}px)`,
      paddingBottom: theme.spacing(17.5),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100% - ${theme.spacing(17.5)}px)`,
      paddingBottom: theme.spacing(70),
    },
  },
  footer: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
  },
  root: {
    height: '100vh',
    width: '100%',
  },
}));

const PrivateView: FC = () => {
  //const {} = useSelector<ApplicationState, StateProps>((state) => ({}), shallowEqual);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const entries: NavEntry[] = [
    {
      id: 'dashboard',
      matIcon: <DashboardIcon />,
      label: t('mainNav.dashboard'),
      target: DASHBOARD_PATH,
      type: 'entry',
    },
    {
      id: 'dashboard',
      matIcon: <DashboardIcon />,
      label: t('mainNav.calendar'),
      target: DASHBOARD_PATH,
      type: 'entry',
    },
    {
      action: () => {
        signOut();
        history.push(LOGIN_PATH);
      },
      id: 'logout',
      matIcon: <ExitToAppIcon />,
      label: t('mainNav.logout'),
      type: 'action',
    },
  ];

  const newBookingButton = (
    <SnoozifyButton
      buttonWidth="<8Chars"
      cta
      small
      id="main-nav-action-configurator"
      onClick={() => {}}
    >
      {t('mainNav.newSnoozify')}
    </SnoozifyButton>
  );

  const userMenu = <AccountButton />;

  const drawerEntries: NavEntry[] = [
    ...entries,
    {
      subEntries: [
        {
          id: 'account-my-rate',
          label: t('mainNav.myRate'),
          target: PLAN_PATH,
          type: 'entry',
        },
        {
          id: 'account-my-invoices',
          label: t('mainNav.myInvoices'),
          target: INVOICES_PATH,
          type: 'entry',
        },
      ],
      id: 'account',
      label: t('mainNav.account'),
      target: ACCOUNT_PATH,
      type: 'entry',
    },
  ];

  return (
    <Grid container className={classes.root} id="private-view-root">
      <MainDrawer entries={drawerEntries} />
      <Grid item xs={12} className={classes.header} id="private-header-root">
        <Hidden smUp>
          <MobileHeader ctaButton={newBookingButton} />
        </Hidden>
        <Hidden xsDown>
          <DesktopHeader entries={entries} menu={userMenu} ctaButton={newBookingButton} />
        </Hidden>
      </Grid>
      <Grid item container xs={12} className={classes.contentWrapper} id="private-content-root">
        <Container className={classes.content} maxWidth="md">
          <Switch>
            <PrivateRoute path={DASHBOARD_PATH} component={DashboardView} />
            <PrivateRoute path={ACCOUNT_PATH} component={AccountView} />
            <Redirect to={DASHBOARD_PATH} />
          </Switch>
        </Container>
        <Grid item xs={12} className={classes.footer} id="private-footer-root">
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PrivateView;
