/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { FC, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

// Data models

// Props
export interface PublicRouteOwnProps {
  component: FC<any>;
  exact?: boolean;
  path: string;
}
export interface PublicRouteStateProps {
  isAuthenticated: boolean;
  redirectUrl: string;
}

export type PublicRouteProps = PublicRouteStateProps & PublicRouteOwnProps;

export const PublicRoute: FC<PublicRouteOwnProps> = (props) => {
  const history = useHistory();
  const { component: Component } = props;
  const { isAuthenticated, redirectUrl } = useSelector<RootState, PublicRouteStateProps>(
    (state) => ({
      isAuthenticated: state.auth.isAuthenticated,
      redirectUrl: state.auth.redirectUrl,
    }),
  );

  useEffect(() => {
    if (isAuthenticated) {
      history.push(redirectUrl);
    }
  }, [history, isAuthenticated, redirectUrl]);

  return (
    <Route
      render={(otherProps) => {
        return <Component {...otherProps} />;
      }}
    />
  );
};

export default PublicRoute;
