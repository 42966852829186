/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-08
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  Popper,
  Paper,
  DialogContent,
  DialogContentText,
  InputProps,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useState, useRef } from 'react';
import { v1 as uuidv1 } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

// Data models
import { Props } from './propTypes';

// Theme
import { WHITE } from 'themes/colors';

// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    padding: '0',
  },
  formControl: {
    'label + &': {
      marginTop: '0',
    },
  },
  formRoot: {
    '& .MuiFormHelperText-root.Mui-error': {
      color: (props) =>
        props.display === 'default'
          ? '#CC2F69 '
          : props.display === 'status'
          ? '#FA6400'
          : '#6DD400',
    },
    '& .Mui-focused .MuiInput-input': {
      border: (props) =>
        props.display === 'default'
          ? '1px solid #00A3FF '
          : props.display === 'status'
          ? '1px solid #FA6400'
          : '1px solid #6DD400',
    },
    '& .MuiInput-input': {
      border: (props) =>
        props.display === 'default'
          ? '1px solid #333333'
          : props.display === 'status'
          ? '1px solid #FA6400'
          : '1px solid #6DD400',
    },
    '& Mui-error .MuiInput-input': {
      border: '1px solid #CC2F69',
    },
  },
  label: {
    fontSize: '1.2rem',
    color: '#333333 !important',
    padding: '0 0 0.8rem 2rem',
    position: 'absolute',
    transform: 'translate(0, -2rem)',
  },
  textRoot: {
    padding: '0',
    height: '4rem',
    width: (props) =>
      props.snoozifyVariant === 'small'
        ? '18.4rem'
        : props.snoozifyVariant === 'medium'
        ? '29rem'
        : '40rem',
    '& :hover': {
      border: '0',
    },
  },
  input: {
    height: '4rem',
    lineHeight: '4rem',
    padding: (props) => (props.icon ? '0 5rem 0 2rem' : '0 0 0 2rem'),
    fontSize: '1.6rem',
    borderRadius: '1.2rem',
    backgroundColor: WHITE,
  },
  inputError: {
    '& .MuiInput-input': {
      border: '1px solid #CC2F69 !important',
    },
  },
  helperText: {
    color: (props) =>
      props.display === 'default' ? '#333333' : props.display === 'status' ? '#FA6400' : '#6DD400',
    padding: (props) => (props.helperText ? '0.8rem 0 0 2rem' : 0),
    maxWidth: '38rem',
    lineHeight: '1.2rem',
    fontSize: '1.2rem',
    margin: (props) => (props.helperText ? '0 0 -0.4rem 0' : 0),
  },
  helperError: {
    color: (props) =>
      props.display === 'default' ? '#CC2F69' : props.display === 'status' ? '#FA6400' : '#6DD400',
  },

  popperRoot: {
    backgroundColor: '#333333',
  },
  infoButton: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  infoIconLabel: {
    display: 'contents',
    '& svg': {
      fill: '#333333',
      width: '1.6rem',
      height: '1.6rem',
    },
  },
  closeButton: {
    padding: '0',
    position: 'absolute',
    right: '1.2rem',
    width: '1rem',
    height: '1rem',
    top: '1.2rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconLabel: {
    display: 'contents',
    '& svg': {
      fill: '#FFFFFF',
      width: '1.5rem',
      height: '1.5rem',
    },
  },

  paper: {
    maxWidth: '27.1rem',
    overflow: 'auto',
    backgroundColor: '#333333',
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #333333 transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#333333 transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent #333333 transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent #333333`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      borderColor: '#333333',
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  dialogContent: {
    color: '#FFFFFF',
    fontSize: '1.2rem',
    paddingTop: '2rem',
  },
  warningIcon: {
    width: '1.2rem',
    height: '1.2rem',
  },
  endAdornment: {
    position: 'absolute',
    right: 0,
  },
  endAdornmentButton: {
    color: '#00A3FF',
    '&:hover,&:active,&:focus': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      width: '1.8rem',
      height: '1.8rem',
    },
  },
}));

const SnoozifyTextField: FC<Props> = (props) => {
  const {
    snoozifyVariant,
    onIconClick,
    icon,
    inputProps,
    helperText,
    display,
    popperText,
    ...rest
  } = props;
  const classes = useStyles(props);
  const inputId = uuidv1();
  const popperId = uuidv1();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [arrowRef, setArrowRef] = React.useState<null | HTMLSpanElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
  };

  const getPopper = () => {
    return (
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorRef.current}
        placement="top"
        transition
        disablePortal={false}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span
          className={classes.arrow}
          ref={(node) => {
            setArrowRef(node);
          }}
        />
        <Paper className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            classes={{ label: classes.iconLabel }}
            edge="start"
            arial-label="close-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <DialogContentText className={classes.dialogContent}>{popperText}</DialogContentText>
          </DialogContent>
        </Paper>
      </Popper>
    );
  };

  return (
    <Grid className={classes.container}>
      {getPopper()}
      <Grid item xs={12} container justify="center" style={{ boxSizing: 'content-box' }}>
        <FormControl classes={{ root: classes.formRoot }}>
          <InputLabel
            className={classes.label}
            variant="standard"
            shrink
            id={inputId}
            required={rest.required}
          >
            <span>
              {rest.label}
              {popperText ? (
                <span style={{ padding: '0 0 0 0.8rem' }}>
                  <IconButton
                    className={classes.infoButton}
                    classes={{ label: classes.infoIconLabel }}
                    aria-describedby={popperId}
                    ref={anchorRef}
                    type="button"
                    onClick={handleClick}
                    tabIndex={-1}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </span>
              ) : null}
            </span>
          </InputLabel>
          <Input
            {...(rest as InputProps)}
            type={rest.type}
            id={inputId}
            aria-describedby={`${inputId}-helper-text`}
            disableUnderline
            classes={{
              root: classes.textRoot,
              input: classes.input,
              formControl: classes.formControl,
              error: classes.inputError,
            }}
            endAdornment={
              icon ? (
                <InputAdornment position="end" className={classes.endAdornment}>
                  <IconButton
                    className={classes.endAdornmentButton}
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (onIconClick) {
                        onIconClick();
                      }
                    }}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                    }}
                    tabIndex={-1}
                  >
                    {icon}
                  </IconButton>
                </InputAdornment>
              ) : null
            }
          />
          <FormHelperText
            error={rest.error}
            classes={{ root: classes.helperText, error: classes.helperError }}
            id={`${inputId}-helper-text`}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {rest.error && display === 'default' ? (
                <WarningTwoToneIcon className={classes.warningIcon} />
              ) : null}
              <span style={{ padding: '0 0 0 0.5rem' }}>{helperText}</span>
            </span>
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

SnoozifyTextField.defaultProps = {
  snoozifyVariant: 'large',
  display: 'default',
};

export default SnoozifyTextField;
