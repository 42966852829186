/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-05-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons';
import * as React from 'react';
import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Data models
import { Props } from './propTypes';
import { RequestStatus } from 'models/common';
import { ConfirmError } from 'models/signup';

// Config
import { LOGIN_PATH } from 'config/routes';

// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  content: {
    height: '100%',
  },
  errorText: {
    whiteSpace: 'pre-line',
  },
  feedbackContainer: {
    color: (props) =>
      props.confirmStatus === RequestStatus.SUCCESS ? green[600] : theme.palette.error.main,
    textAlign: 'center',
  },
  feedbackIcon: {
    fontSize: theme.typography.fontSize * 20,
  },
  logoContainer: {
    textAlign: 'center',
  },
  root: {
    height: '100%',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

const EmailConfirmation: FC<Props> = (props) => {
  const { confirm, confirmError, confirmStatus, history, location } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const query = new URLSearchParams(location.search);
  const code = query.get('oobCode');
  const { t } = useTranslation();
  const [countDown, setCountDown] = useState<number>(5);
  const [verified, setVerified] = useState<boolean>(false);

  // Call verify routine
  useEffect(() => {
    if (!verified && confirmStatus === RequestStatus.IDLE && code) {
      confirm(code);
    }
  }, [code, confirm, confirmStatus, verified]);

  // Redirect to login if succeeded
  useEffect(() => {
    if (countDown <= 0) {
      history.push(LOGIN_PATH);
    }
  }, [countDown, history]);

  // Set verified status for further update action
  useEffect(() => {
    if (confirmStatus === RequestStatus.SUCCESS) {
      setVerified(true);
    }
  }, [confirmStatus]);

  // Set new countdown value each second
  useEffect(() => {
    if (verified && countDown > 0) {
      const timer = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      // Clear timer on each update to avoid memory leaks
      return () => {
        clearInterval(timer);
      };
    }
  }, [countDown, setCountDown, verified]);

  const getErrorMessage = () => {
    switch (confirmError) {
      case ConfirmError.EXPIRED: {
        return t('signUp.confirmCodeExpired');
      }
      case ConfirmError.INVALID: {
        return t('signUp.confirmCodeInvalid');
      }
      default: {
        return '';
      }
    }
  };

  const getContent = (): ReactElement<'div'> => {
    switch (confirmStatus) {
      case RequestStatus.LOADING: {
        return (
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center" spacing={4}>
              <Grid item xs={12} className={classes.logoContainer}>
                <CircularProgress size={theme.typography.fontSize * 20} />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="h4" className={classes.uppercase}>
                  {t('signUp.justOneMoment')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="h5">
                  {t('signUp.yourEmailIsGettingVerified')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      case RequestStatus.SUCCESS: {
        return (
          <Grid item xs={12} className={classes.feedbackContainer}>
            <CheckCircleOutlineIcon className={classes.feedbackIcon} />
            <Typography align="center" variant="h5">
              {t('signUp.youWillBeRedirectedInSeconds', { count: countDown })}
            </Typography>
          </Grid>
        );
      }
      case RequestStatus.ERROR: {
        return (
          <Grid item xs={12} className={classes.feedbackContainer}>
            <CancelOutlinedIcon className={classes.feedbackIcon} />
            <Typography align="center" variant="h5" style={{ whiteSpace: 'pre-line' }}>
              {getErrorMessage()}
            </Typography>
          </Grid>
        );
      }
      default: {
        return (
          <Grid item xs={12}>
            <Typography align="center">{t('signUp.weWillStartSoon')}</Typography>
          </Grid>
        );
      }
    }
  };

  return (
    <Fragment>
      <Helmet title={`${t('signUp.confirmTitle')} | Snoozify`}></Helmet>
      <Container className={classes.root} maxWidth="sm">
        <Grid container className={classes.content} alignItems="center" justify="center">
          {getContent()}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default EmailConfirmation;
