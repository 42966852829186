/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import moment from 'moment';

// Data models
import { UserState } from 'models/user/state';
import { UserAction, UserActionType } from 'models/user/actions';
import { FireStoreError } from 'models/firebase';
import { RequestStatus } from 'models/common';
import { Reducer } from 'redux';
import { Salutation } from 'models/user';

export const initialState: UserState = {
  loadUserError: FireStoreError.NONE,
  loadUserStatus: RequestStatus.IDLE,
  updateUserProfileUnsubscribe: () => {},
  managedProfile: null,
  profile: {
    createdAt: moment(),
    email: '',
    initialLogin: true,
    privacyAcceptedAt: moment(),
    termsAcceptedAt: moment(),
    accountType: 'primary',
    profileInfoUpdated: moment(),
    uid: '',
    invoiceAddress: {
      company: '',
      salutation: Salutation.none,
      firstName: '',
      lastName: '',
      additionalAddress: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      place: '',
      country: 'DEU',
    },
    paymentDetails: {
      IBAN: '',
      bankName: '',
      accountHolder: '',
      BIC: '',
      customerId: '',
    },
  },
};

const userReducer: Reducer<UserState, UserAction> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionType.LOAD_USER_STATUS: {
      return {
        ...state,
        loadUserError: action.error,
        loadUserStatus: action.status,
      };
    }
    case UserActionType.USER_PROFILE: {
      return {
        ...state,
        profile: action.profile,
      };
    }
    case UserActionType.SET_MANAGED_USER_PROFILE: {
      return {
        ...state,
        managedProfile: action.profile,
      };
    }
    case UserActionType.UPDATE_USER_PROFILE_UNSUBSCRIBE: {
      return {
        ...state,
        updateUserProfileUnsubscribe: action.unsubscribe,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
