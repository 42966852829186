/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

// Action creators
import { changeEmail } from 'store/auth/authSlice';

// Presentational component
import ChangeEmailDialog from './ChangeEmailDialog';

// Data models
import { StateProps, DispatchProps } from './propTypes';
import { RootState, AppDispatch } from 'store';

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (state) => ({
  error: state.auth.changeEmailError,
  progress: state.auth.changeEmailState,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (dispatch: AppDispatch) => ({
  onChangeEmail: (email) => {
    dispatch(changeEmail({ email: email }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailDialog);
