/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Stripe } from 'stripe';

// Data models
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';
import { getJsonHeaders } from 'utils/requestHeaders';

// Utils
import { createAppThunk } from 'utils/appAction';

export type PaymentPriceState = {
  fetchPricesStatus: RequestStatus;
  prices: Array<Stripe.Price>;
};

export type UpdatePricesPayload = {
  prices: Array<Stripe.Price>;
};

export const initialState: PaymentPriceState = {
  fetchPricesStatus: RequestStatus.IDLE,
  prices: [],
};

const sliceName = '@@payment/prices';

export const getPrices = createAppThunk(
  sliceName + '/getPrices',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await fetch(`${BACKEND_URL}/snoozifyApp/payment/prices`, {
        headers: getJsonHeaders,
      });
      const prices = await res.json();
      dispatch(updatePrices({ prices }));
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

const priceSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updatePrices(state, action: PayloadAction<UpdatePricesPayload>) {
      state.prices = action.payload.prices;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrices.pending, (state, _) => {
      state.fetchPricesStatus = RequestStatus.LOADING;
    });
    builder.addCase(getPrices.fulfilled, (state, _) => {
      state.fetchPricesStatus = RequestStatus.IDLE;
    });
    builder.addCase(getPrices.rejected, (state, action) => {
      state.fetchPricesStatus = RequestStatus.ERROR;
    });
  },
});

export const { updatePrices } = priceSlice.actions;

export default priceSlice.reducer;
