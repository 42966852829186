/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-02-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { Language } from 'models/common';
import { CommonActionType, LanguageChangeAction, ToggleDrawerAction } from 'models/common/actions';

export const changeLanguage = (language: Language): LanguageChangeAction => ({
  language,
  type: CommonActionType.LANGUAGE_CHANGE,
});

export const toggleDrawer = (open: boolean): ToggleDrawerAction => ({
  open,
  type: CommonActionType.TOGGLE_DRAWER,
});
