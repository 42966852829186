/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies

// Data models
import { LoginState } from 'models/login/state';
import { LoginAction, LoginActionType } from 'models/login/actions';
import { LoginError, RequestPasswordResetError, ResetPasswordError } from 'models/login';
import { RequestStatus } from 'models/common';
import { Reducer } from 'redux';

export const initialState: LoginState = {
  loginData: {
    email: '',
    password: '',
    remember: true,
  },
  loginError: LoginError.NONE,
  loginStatus: RequestStatus.IDLE,
  requestPasswordResetData: {
    email: '',
  },
  requestPasswordResetError: RequestPasswordResetError.NONE,
  requestPasswordResetStatus: RequestStatus.IDLE,
  resetPasswordError: ResetPasswordError.NONE,
  resetPasswordStatus: RequestStatus.IDLE,
};

const loginReducer: Reducer<LoginState, LoginAction> = (state = initialState, action) => {
  switch (action.type) {
    case LoginActionType.DATA: {
      return {
        ...state,
        loginData: action.data,
      };
    }
    case LoginActionType.LOGIN_STATUS: {
      return {
        ...state,
        loginError: action.error,
        loginStatus: action.status,
      };
    }
    case LoginActionType.DATA_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;
