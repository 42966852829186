/**
 * @copyright 2020 Systementwicklung Tim Lange
 * @created 2020-08-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as firebase from 'firebase/app';
import 'firebase/storage';

// Config

// Utils

// Data models
import { RequestStatus } from 'models/common';
import { createAppThunk } from 'utils/appAction';
import { AuthError, ChangeEmailError, DeleteAccountError, ChangePasswordError } from 'models/auth';
import { SnoozifyUser } from 'models/user';
import { updateUser } from 'store/user/actions';

// Utils
import { postSessionRequest } from 'utils/requestHandler';

const sliceName = '@@auth';

export interface AuthState {
  authError: AuthError;
  configuratorRedirectUrl: string;
  isAuthenticated: boolean;
  sessionToken: string | null;
  redirectUrl: string;
  user?: firebase.User;
  changeEmailState: RequestStatus;
  changeEmailError: ChangeEmailError;
  changePasswordState: RequestStatus;
  changePasswordError: ChangePasswordError;
  deleteAccountState: RequestStatus;
  deleteAccountError: DeleteAccountError;
  sessionRequestStatus: RequestStatus;
}

export interface SetSessionTokenPayload {
  token: string | null;
}

export interface SetEmailChangeError {
  error: ChangeEmailError;
}

export interface SetPasswordChangeError {
  error: ChangePasswordError;
}

export interface SetDeleteAccountError {
  error: DeleteAccountError;
}

export interface SetRedirectUrlPayload {
  url: string;
}

export interface SetConfiguratorUrlPayload {
  url: string;
}

export interface UpdateAuthDataPayload {
  error: AuthError;
  isAuthenticated: boolean;
  user?: firebase.User;
}

export const initialState: AuthState = {
  authError: AuthError.NONE,
  configuratorRedirectUrl: '',
  isAuthenticated: false,
  redirectUrl: '/dashboard',
  user: undefined,
  changeEmailState: RequestStatus.IDLE,
  changeEmailError: ChangeEmailError.NONE,
  changePasswordState: RequestStatus.IDLE,
  changePasswordError: ChangePasswordError.NONE,
  deleteAccountState: RequestStatus.IDLE,
  deleteAccountError: DeleteAccountError.NONE,
  sessionToken: null,
  sessionRequestStatus: RequestStatus.IDLE,
};

export const deleteAccount = createAppThunk(
  sliceName + '/deleteAccount',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await firebase
          .firestore()
          .collection('/versions/v1/users')
          .doc(currentUser.uid)
          .delete();

        await currentUser.delete();
      }
    } catch (err) {
      dispatch(setDeleteAccountError({ error: err.code as DeleteAccountError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const changePassword = createAppThunk<void, { newPassword: string }>(
  sliceName + '/changePassword',
  async ({ newPassword }, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await currentUser.updatePassword(newPassword);
      }
    } catch (err) {
      dispatch(setPasswordChangeError({ error: err.code as ChangePasswordError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const changeEmail = createAppThunk<void, { email: string }>(
  sliceName + '/changeEmail',
  async ({ email }, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await currentUser.updateEmail(email);
        await currentUser.sendEmailVerification();
        const userProfile = getState().user.profile;
        const updatedUserProfile: SnoozifyUser = {
          ...userProfile,
          email: email,
        };
        dispatch(updateUser(updatedUserProfile));
      }
    } catch (err) {
      dispatch(setEmailChangeError({ error: err.code as ChangeEmailError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const requestSessionToken = createAppThunk(
  sliceName + '/requestSessionToken',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        const payload = await postSessionRequest(idToken);
        dispatch(setSessionToken({ token: payload.payload.jwt }));
        setTimeout(() => {
          dispatch(setSessionToken({ token: null }));
        }, 10000);
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const signOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {});
};

const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSessionToken(state, action: PayloadAction<SetSessionTokenPayload>) {
      state.sessionToken = action.payload.token;
    },
    setEmailChangeError(state, action: PayloadAction<SetEmailChangeError>) {
      state.changeEmailError = action.payload.error;
    },
    setPasswordChangeError(state, action: PayloadAction<SetPasswordChangeError>) {
      state.changePasswordError = action.payload.error;
    },
    setDeleteAccountError(state, action: PayloadAction<SetDeleteAccountError>) {
      state.deleteAccountError = action.payload.error;
    },
    setRedirectUrl(state, action: PayloadAction<SetRedirectUrlPayload>) {
      state.redirectUrl = action.payload.url;
    },
    setConfiguratorUrl(state, action: PayloadAction<SetConfiguratorUrlPayload>) {
      state.configuratorRedirectUrl = action.payload.url;
    },
    updateAuthData(state, action: PayloadAction<UpdateAuthDataPayload>) {
      state.authError = action.payload.error;
      state.user = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestSessionToken.pending, (state, _) => {
      state.sessionRequestStatus = RequestStatus.LOADING;
    });
    builder.addCase(requestSessionToken.fulfilled, (state, _) => {
      state.sessionRequestStatus = RequestStatus.IDLE;
    });
    builder.addCase(requestSessionToken.rejected, (state, action) => {
      state.sessionRequestStatus = RequestStatus.ERROR;
    });

    builder.addCase(deleteAccount.pending, (state, _) => {
      state.deleteAccountState = RequestStatus.LOADING;
    });
    builder.addCase(deleteAccount.fulfilled, (state, _) => {
      state.deleteAccountState = RequestStatus.IDLE;
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.deleteAccountState = RequestStatus.ERROR;
    });

    builder.addCase(changeEmail.pending, (state, _) => {
      state.changeEmailState = RequestStatus.LOADING;
    });
    builder.addCase(changeEmail.fulfilled, (state, _) => {
      state.changeEmailState = RequestStatus.IDLE;
    });
    builder.addCase(changeEmail.rejected, (state, action) => {
      state.changeEmailState = RequestStatus.ERROR;
    });

    builder.addCase(changePassword.pending, (state, _) => {
      state.changePasswordState = RequestStatus.LOADING;
    });
    builder.addCase(changePassword.fulfilled, (state, _) => {
      state.changePasswordState = RequestStatus.IDLE;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePasswordState = RequestStatus.ERROR;
    });
  },
});

export const {
  setSessionToken,
  setRedirectUrl,
  updateAuthData,
  setEmailChangeError,
  setPasswordChangeError,
  setDeleteAccountError,
  setConfiguratorUrl,
} = authSlice.actions;

export default authSlice.reducer;
